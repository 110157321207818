<template>
    <form @submit.prevent="additem">
        <div class="modal fade" id="modaladdinvoice" tabindex="-1" aria-labelledby="modaladdinvoice">

            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title">{{ title }}
                            </h5>
                            <span for=""> {{ sub }} </span>
                        </div>
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                            ref="buttonclosemodaladdinvoice">
                            <span>×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- <div class="page-header">
                            <div class="page-title">
                                <h4>Categories</h4>
                                <h6>Manage your purchases</h6>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Pilih SO</label>
                                        <model-select :options="sodata" placeholder="Pilih SO"
                                            v-model="so_id" required :isError="soCek">
                                        </model-select>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Pilih Item</label>
                                        <model-select :options="itemdata" placeholder="Pilih Item" v-model="item_id"
                                            :isError="itemCek">
                                        </model-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Pilih Gudang</label>
                                        <model-select :options="warehousedataList" placeholder="Pilih Gudang"
                                            v-model="warehouse_id" :isDisabled="item_id == ''" :isError="warehouseCek">
                                        </model-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Harga</label>
                                        <input type="text" class="form-control form-control-sm" v-model="harga"
                                            placeholder="Rp.0" @input="formatHarga(harga)" required disabled />

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Diskon</label>
                                        <div class="d-flex justify-content-between gap-2">
                                            <select class="form-control form-white" data-placeholder="Choose a color..."
                                                name="discount_type" v-model="discount_type"
                                                style="width: 25%!important;">
                                                <option value="persen">%</option>
                                                <option value="rupiah">Rp.</option>
                                            </select>
                                            <input type="text" class="form-control form-control-sm" v-model="discount"
                                                placeholder="Rp.0" @input="formatDiscount(discount)"
                                                v-if="discount_type == 'rupiah'" required />
                                            <input type="number" class="form-control form-control-sm" v-model="discount"
                                                placeholder="0" v-if="discount_type == 'persen'" required />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" v-if="isMarketing == true">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Pilih Marketing</label>
                                        <model-select :options="marketingdata" placeholder="Select Marketing"
                                            v-model="marketing_id" :isError="marketingCek">
                                        </model-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Qty </label>
                                        <input type="number" class="form-control form-control-sm"
                                            aria-controls="DataTables_Table_0" v-model="qty" min="1" required />
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-lg-12">
                            <button class="btn btn-submit me-2" type="submit">Save</button>
                            <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<style></style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ModelSelect } from 'vue-search-select';
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, rupiah, rupiahInput, rupiahoutput, goPage, AlertBottom } from "@/assets/js/function";

export default {
    data() {
        return {
            //title
            title: "",
            sub: "",


            data: "",
            config: "",
            token: "",

            //add items
            marketing_id: "",
            kategori_id: "",
            so_id:"",
            warehouse_id: "",
            item_id: "",
            harga: "0",
            discount_type: "persen",
            discount: 0,
            qty: 1,
            minimal_order: 0,
            marketingCek: false,
            kategoriCek: false,
            soCek:false,
            itemCek: false,
            warehouseCek: false,
            status: "",
            id: "",

            //pilih kategori
            active_id: "",
            settings: {
                itemsToShow: 10,
                snapAlign: "center",
            },

            breakpoints: {
                0: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                575: {
                    itemsToShow: 4,
                    snapAlign: "center",
                },
                // 700px and up
                767: {
                    itemsToShow: 6,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 8,
                    snapAlign: "start",
                },
            },
            modalClass: "",

            //sent add item 
            warehousedataList: [],


        }


    },

    props: {
        marketingdata: Object,
        kategoridata: Object,
        sodata: Object,
        itemdata: Object,
        invoiceEdited: Object,
        isMarketing: Boolean,
        warehousedata: Object,
        tingkatHarga: Number,
    },
    watch: {
        discount_type(newValue) {
            this.discount = 0;
        },
        kategori_id(newValue, oldValue) {
            this.kategoriCek = false;
            if (newValue != undefined) {
                this.$emit('id_kategori_selected', newValue)
            } else {
                this.$emit('id_kategori_selected', '')
            }
        },

        so_id(newValue, oldValue) {
            this.soCek = false;
            if (newValue != undefined) {
                this.$emit('so_selected', newValue)
            } else {
                this.$emit('so_selected', '')
            }
        },

        item_id(newValue, oldValue) {
            this.itemCek = false;
            const index = this.itemdata.findIndex((record) => record.id === newValue);

            let isMarketing = false;
            if (this.itemdata[index] != undefined && this.itemdata[index].ismarketing == 'AKTIF') {
                isMarketing = true;
            }
            this.$emit('id_item_selected', isMarketing);
            if (this.itemdata[index] != undefined) {
                this.kategori_id = this.itemdata[index].id_kategori;
            }

            if (index > -1) {
                if (this.tingkatHarga == 0) {
                    this.harga = rupiah(this.itemdata[index].rate);
                } else {
                    this.harga = rupiah(this.itemdata[index]['rate' + this.tingkatHarga]);
                }
            }
            this.cekQty(newValue);
        },
        marketing_id(newValue, oldValue) {
            this.marketingCek = false;
            // this.$emit('id_marketing_selected', newValue)
        },
        warehouse_id(newValue, oldValue) {
            this.warehouseCek = false;

            const index_yang_dipilih = this.warehousedataList.findIndex((record) => record.id === newValue);
            if (this.warehousedataList[index_yang_dipilih] != undefined && this.warehousedataList[index_yang_dipilih].jumlah == 0) {
                AlertPopup("error", "", "ITEM DIGUDANG INI KOSONG", 1500, false);
                this.warehouse_id = "";
            }
        },
        invoiceEdited: {
            handler(newVal) {
                if (newVal) {
                    
                    // this.marketing_id = newVal.marketing_id;
                    // this.kategori_id = newVal.kategori_id;
                    // this.$emit('id_kategori_selected', newVal.kategori_id);

                    // this.item_id = newVal.item_id;
                    // this.qty = newVal.qty;
                    this.status = newVal.status;
                    this.so_id = newVal.id;
                    // this.harga = rupiahInput(newVal.harga);
                    // this.discount_type = newVal.discount_type;
                    // this.warehouse_id = newVal.id_warehouse;


                    // if (this.discount_type != 'persen') {
                    //     this.discount = rupiahInput(newVal.discount_amount);
                    // } else {
                    //     this.discount = newVal.discount_amount;
                    // }


                    if (newVal.item_id != "") {
                        this.title = "Edit Item";
                        this.sub = "Update data item";
                    } else {
                        this.title = "Select Item";
                        this.sub = "Add item";
                    }
                }

            },

        },


    },

    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }

    },


    methods: {
        rupiah,

        formatHarga(number) {
            this.harga = rupiahInput(number);
        },
        formatDiscount(number) {
            this.discount = rupiahInput(number);
        },
        additem() {
            
            if (this.so_id == '') {
                this.soCek = true;
            }
            
            if (this.soCek == true) {
                AlertPopup('error', '', 'Some fields are empty', 2000, true);
                return true;
            }

            
            // let discount_amount = 0;
            // if (this.discount_type == 'persen') {
            //     discount_amount = this.discount;
            // } else if (this.discount_type == 'rupiah' && this.discount_amount > 0) {
            //     discount_amount = rupiahoutput(this.discount);
            // } else {
            //     discount_amount = this.discount;
            // }
            const invoice_object = {
                // id_items: this.item_id,
                // id_marketing: idmarketing,
                // qty: this.qty,
                id_items: this.so_id,
                id: this.so_id,
                status: this.status,
                // harga: rupiahoutput(this.harga),
                // discount: discount_amount,
                // discount_type: this.discount_type,
                // id_warehouse: this.warehouse_id,
            }



            this.$emit('invoice_object', invoice_object);
            const openButton = this.$refs.buttonclosemodaladdinvoice;
            openButton.click();

        },
        cekQty(id_item) {
            this.warehousedataList = [];
            // this.warehousedataListTo = [];
            ShowLoading();
            let formData = new FormData();
            formData.append('id_item', id_item);
            // Object.entries(this.currentData).forEach(([key, value]) => {

            //     formData.append(key, value);
            // });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }



            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'movementitem_cek_qty';
            } else {
                this.api = base_url + 'movementitem_cek_qty';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.status == true) {
                        response.data.data.forEach((item, index) => {
                            if (item.id != null && item.jumlah != null && item.jumlah >= 0) {
                                let item_prepare = { ...item, value: item.id, text: item.nama_gudang + ' (' + item.jumlah + ')', };
                                this.warehousedataList.push(item_prepare)
                            }
                        });

                        if (response.data.data.length > 0) {
                            if (response.data.data[0].minimal_order != null) {
                                this.minimal_order = response.data.data[0].minimal_order;
                            } else {
                                this.minimal_order = 0;
                            }
                        }



                        CloseLoading();
                        if (this.warehousedataList.length == 0 && this.item_id != "") {
                            AlertPopup("error", "", "ITEM INI BELUM ADA DI GUDANG MANAPUN", 1500, false);
                        }

                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

    },
    components: {

        ModelSelect

    },
}
</script>