<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1" :items="items" />

                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top">
                            <div class="search-set mb-2">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Cari..." aria-controls="DataTables_Table_0"
                                                v-model="query" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="gap-2 d-flex justify-content-end align-items-center ">
                                <!-- <div class="me-2">
                                    <div class="d-flex flex-column justify-content-center align-items-center ">
                                        <a href="javascript:void(0);" @click="exportExcel()"><img
                                                src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                                        <span style="font-size: 10px; margin-top: -8px;">excel</span>
                                    </div>
                                </div> -->
                                <!-- <div>
                                    <div class="form-group" style="margin-bottom: 0px!important;">
                                        
                                        <div class="input-groupicon" style="z-index: 100;">
                                            <datepicker class="picker" v-model="currentData.dfrom" :editable="true"
                                                :clearable="false" required />
                                            <a class="addonset">
                                                <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                            </a>
                                        </div>
                                    </div>
                                </div> 
                                <div class="">
                                    <div class="form-group" style="margin-bottom: 0px!important;">
                                        
                                        <div class="input-groupicon" style="z-index: 100;">
                                            <datepicker class="picker" v-model="currentData.dto" :editable="true"
                                                :clearable="false" required />
                                            <a class="addonset">
                                                <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                            </a>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="">
                                    <button class="btn btn-sm btn-primary " @click="index"> <vue-feather
                                            type="filter"></vue-feather></button>
                                </div> -->
                            </div>
                            <!-- <div class="wordset">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="@/assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="@/assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="@/assets/img/icons/printer.svg" alt="img" /></a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <!-- /Filter -->
                        <!-- <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- /Filter -->
                        <div class="table-responsive">
                            <a-table :columns="columns" :data-source="filteredData" @change="onChange">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>

                                    <template v-if="column.key === 'invoice_date'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.invoice_date }}</a>
                                        </div>
                                    </template>

                                    <template v-if="column.key === 'invoice_no'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.invoice_no }}</a>
                                        </div>
                                    </template>
                                    <!-- <template v-if="column.key === 'code_project'">
                                        <div class="productimgname">
                                            
                                            <a href="javascript:void(0);">{{ record.code_project }}</a>
                                        </div>
                                    </template> -->


                                    <template v-if="column.key === 'nama_customer'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.nama_customer }}</a>
                                        </div>
                                    </template>

                                    <template v-if="column.key === 'grand_total'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ rupiah(record.grand_total) }}</a>
                                        </div>
                                    </template>
                                    <template v-else-if="column.key === 'action'">
                                        <!-- <a class="me-3" @click="show_izitoast">
                                            <img src="@/assets/img/icons/eye.svg" alt="img" />
                                        </a> -->
                                        <a class="text-secondary me-3" href="javascript:void(0);"
                                            @click="share(record.slug); showModal('share');">
                                            <vue-feather type="share-2" data-bs-toggle="tooltip"
                                                title="fe fe-share-2"></vue-feather>

                                        </a>
                                        <a href="javascript:void(0);"
                                            @click="edit(record.slug); viewQuotation(record.slug)" class="me-3">
                                            <vue-feather type="file-text"></vue-feather>
                                        </a>
                                        <router-link class="me-3" to="editinvoice" @click="edit(record.id);">
                                            <img src="@/assets/img/icons/edit.svg" alt="img" />
                                        </router-link>
                                        <a class="confirm-text" href="javascript:void(0);"
                                            @click="terminate(record.id, record.invoice_date); showModal('terminate');">
                                            <img src="@/assets/img/icons/delete.svg" alt="img" />
                                        </a>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Delete Data</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        Apakah anda yakin ingin menghapus data ini?
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary"
                            @click="hideModal2('terminate')">cancel</button>
                        <button type="submit" class="btn  btn-danger">Delete</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="share" ref="share" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Purchase Order Link</h5>
                    <button type="button" class="close" @click="hideModal2('share')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Link </label>
                            <input type="text" v-model="slugquotation" placeholder="" />
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary" @click="hideModal2('share')">Close</button>
                        <button type="button" class="btn  btn-primary d-flex align-items-center justify-content-center"
                            @click="copyclipboard()"><vue-feather type="copy" data-bs-toggle="tooltip"
                                title="fe fe-copy" class="me-2"></vue-feather>
                            Copy</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom, rupiah, openInNewTab } from "@/assets/js/function";

export default {
    data() {
        return {
            filter: false,
            title: "Daftar Sales Invoice",
            title1: "atur data sales invoice",
            items: [
                {
                    text: "Tambah Sales Invoice Baru",
                    addname: "/addinvoice",
                },
            ],

            //data
            data: [],
            data_customer: [],
            columns: [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no.toLowerCase();
                            b = b.no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Tanggal",
                    dataIndex: "invoice_date",
                    key: "invoice_date",
                    sorter: {
                        compare: (a, b) => {
                            a = a.invoice_date.toLowerCase();
                            b = b.invoice_date.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Sales Invoice",
                    dataIndex: "invoice_no",
                    key: "invoice_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.invoice_no.toLowerCase();
                            b = b.invoice_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Faktur SO",
                    dataIndex: "sales_order_no",
                    key: "sales_order_no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.sales_order_no.toLowerCase();
                            b = b.sales_order_no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                // {
                //     title: "Kode Projek",
                //     dataIndex: "code_project",
                //     key: "code_project",
                //     sorter: {
                //         compare: (a, b) => {
                //             const codeProjectA = a.code_project ? a.code_project.toLowerCase() : '';
                //             const codeProjectB = b.code_project ? b.code_project.toLowerCase() : '';
                //             return codeProjectA > codeProjectB ? -1 : codeProjectB > codeProjectA ? 1 : 0;
                //         },
                //     },
                // },
                {
                    title: "Nama Pelanggan",
                    dataIndex: "nama_customer",
                    key: "nama_customer",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama_customer.toLowerCase();
                            b = b.nama_customer.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Total",
                    dataIndex: "grand_total",
                    key: "grand_total",
                    sorter: {
                        compare: (a, b) => {
                            a = a.grand_total.toLowerCase();
                            b = b.grand_total.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Aksi",
                    key: "action",
                    sorter: false,
                },
            ],

            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            nama_terminate: "",

            slugquotation: "",


            //filter
            query: "",
            currentData: {
                dfrom: "",
                dto: "",
            }
        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        if (localStorage.getItem('dfrom') !== null) {
            this.currentData.dfrom = new Date(localStorage.getItem('dfrom'));
        } else {
            this.currentData.dfrom = new Date();
        }
        if (localStorage.getItem('dto') !== null) {
            this.currentData.dto = new Date(localStorage.getItem('dto'));
        } else {
            this.currentData.dto = new Date();
        }



        this.index();
    },

    computed: {
        filteredData() {
            // Filter data based on selected jenis_kehadiran
            if (this.query == '') {
                return this.data; // If no filter selected, return all data
            }
            const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison

            const filtered = this.data.filter(item =>
                item.nama_customer.toLowerCase().includes(lowerCaseQuery) ||
                item.invoice_date.toLowerCase().includes(lowerCaseQuery) ||
                item.invoice_no.toLowerCase().includes(lowerCaseQuery) ||
                (item.code_project ? item.code_project.toLowerCase().includes(lowerCaseQuery) : false) ||
                item.grand_total.toLowerCase().includes(lowerCaseQuery)
            );

            // Sort the filtered data by 'no' in ascending order
            const sorted = filtered.sort((a, b) => a.no - b.no);

            // Reset 'no' values to start from 1
            const resetNos = sorted.map((item, index) => {
                return {
                    ...item,
                    no: index + 1 // Set 'no' to index + 1
                };
            });

            return resetNos;
        }
    },
    watch: {
        'currentData.dfrom': {
            handler(newValue) {

                const date = new Date(newValue);

                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');

                const formattedDate = `${year}-${month}-${day}`;


                localStorage.setItem('dfrom', formattedDate);
            }
        },
        'currentData.dto': {
            handler(newValue) {
                const date = new Date(newValue);

                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');

                const formattedDate = `${year}-${month}-${day}`;

                localStorage.setItem('dto', formattedDate);
            }
        }


    },
    methods: {
        rupiah,
        viewQuotation(slug) {
            localStorage.setItem('slug', slug);
            openInNewTab('viewinvoice');
        },
        copyclipboard() {
            try {
                navigator.clipboard.writeText(this.slugquotation);
                AlertBottom('Copied to clipboard');
                // Optional: Display a success message to the user
            } catch (error) {
                console.error("Failed to copy to clipboard:", error);
                // Optional: Display an error message to the user
            }
        },
        show_izitoast() {
            AlertBottom('tessst');
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';
        },


        // showAlert() {
        //     // Use sweetalert2
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         showCancelButton: true,
        //         confirmButtonColor: "#1BA0E2",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire("Deleted!", "Your file has been deleted.");
        //         }
        //     });
        // },
        viewInvoice(slug) {
            localStorage.setItem('slug', slug)
            openInNewTab('view');
        },
        index() {


            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                // Create a new Date object
                const date = new Date(value);

                // Use built-in methods to get year, month, and day
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                // console.log(value);
                formData.append(key, formattedDate);
            });

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'invoices';
            } else {
                this.api = base_url + 'invoices';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        this.data = [];
                        this.data_customer = response.data.data.customers;
                        if (response.data.data.invoices.length > 0) {
                            response.data.data.invoices.forEach((item, index) => {
                                // const indexCustomer = this.data_customer.findIndex((record) => record.id === item.id_customer);
                                // let fullnameCustomer = "Walk in";
                                // if (indexCustomer > -1) {
                                //     fullnameCustomer = this.data_customer[indexCustomer].fullname;
                                // }
                                let item_prepare = { ...item, no: (index + 1) };
                                this.data.push(item_prepare);
                            });
                        }

                        //save customer to local
                        localStorage.setItem('customer', JSON.stringify(response.data.data.index.customer));
                        //save item to local
                        localStorage.setItem('item', JSON.stringify(response.data.data.index.item));
                        // console.log(response.data.data.index.item)
                        //save kategori to local
                        localStorage.setItem('kategori', JSON.stringify(response.data.data.index.kategori));
                        //save purchase to local
                        // localStorage.setItem('list_items_in_warehouse', JSON.stringify(response.data.data.index.data_list_items_in_warehouse));
                        localStorage.setItem('warehouse', JSON.stringify(response.data.data.index.warehouse));
                        localStorage.setItem('ekspedisi', JSON.stringify(response.data.data.index.ekspedisi));
                        localStorage.setItem('sales_order', JSON.stringify(response.data.data.index.sales_order));
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        exportExcel() {
            this.index();
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                // Create a new Date object
                const date = new Date(value);

                // Use built-in methods to get year, month, and day
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                formData.append(key, formattedDate);
            });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'invoices_export';
            } else {
                this.api = base_url + 'invoices_export';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        openInNewTab(response.data.data.file_report);
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        edit(data) {
            localStorage.setItem('currentData', JSON.stringify(data));
        },
        share(slug) {
            const url = window.location.origin;

            this.slugquotation = url + '/viewinvoice?token=' + slug;
        },

        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);
            formData.append("nama", this.nama_terminate);

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'invoices_delete';
            } else {
                this.api = base_url + 'invoices_delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id, nama) {
            this.id_terminate = id;
            this.nama_terminate = nama;
            this.tanggal_terminate = new Date();
        }
    },
    name: "productlist",
};
</script>

<style>
.ant-tooltip {
    display: none !important;
}
</style>