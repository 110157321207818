<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">

          <div class="login-img">
            <img src="../../../assets/img/banner_login.png" alt="img" size="10" />

          </div>
          <div class="login-content">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="../../../assets/img/logo.png" alt="img" />
              </div>
              <router-link to="/index" class="login-logo logo-white">
                <img src="../../../assets/img/logo-white.png" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Change Password</h3>
                <div class="d-flex justify-content-between">
                  <div>
                    <h4>Please enter the new password</h4>
                  </div>

                  <!-- <div>
                    Didn't get the OTP?
                    <a @click="sendOtp()" class="hover-a">send again</a>
                  </div> -->
                </div>

              </div>
              <Form class="login" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <div class="form-login">
                  <label>New Password </label>
                  <div class="pass-group">
                    <Field v-if="showPassword" name="password" type="text" class="form-control pass-input"
                      placeholder="" :class="{ 'is-invalid': errors.password }" v-model="password" />
                    <Field v-else name="password" type="password" class="form-control pass-input" placeholder=""
                      :class="{ 'is-invalid': errors.password }" v-model="password" />

                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                    <div class="position-absolute  end-0 top-0 h-100 d-flex align-items-center"
                      v-if="!(errors.password)">
                      <span @click="toggleShow" class="fas toggle-password" :class="{
                        'fa-eye': showPassword,
                        'fa-eye-slash': !showPassword,
                      }"></span>
                    </div>
                  </div>
                  <label>Confirm New Password</label>
                  <div class="pass-group">
                    <Field v-if="showPasswordConfirm" name="passwordconfirm" type="text" class="form-control pass-input"
                      placeholder="" :class="{ 'is-invalid': errors.passwordconfirm }" v-model="passwordconfirm" />
                    <Field v-else name="passwordconfirm" type="password" class="form-control pass-input" placeholder=""
                      :class="{ 'is-invalid': errors.passwordconfirm }" v-model="passwordconfirm" />
                    <div class="invalid-feedback">{{ errors.passwordconfirm }}</div>
                    <div class="emailshow text-danger" id="passwordconfirm"></div>
                    <div class="position-absolute  end-0 top-0 h-100 d-flex align-items-center"
                      v-if="!(errors.passwordconfirm)">
                      <span @click="toggleShowConfirm" class="fas toggle-password" :class="{
                        'fa-eye': showPasswordConfirm,
                        'fa-eye-slash': !showPasswordConfirm,
                      }"></span>
                    </div>
                  </div>
                </div>

                <div class="form-login">
                  <button type="submit" class="btn btn-login">Confirm</button>
                  <!-- <router-link class="btn btn-login" to="pin"></router-link> -->
                </div>
              </Form>
              <div class="signinform text-center d-flex justify-content-start">
                <div>
                  <router-link to="/" class="hover-a">Change account</router-link>
                </div>
              </div>
              <!-- <div class="form-setlogin">
                <h4>Or sign up with</h4>
              </div> -->
              <!-- <div class="form-sociallink">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="../../../assets/img/icons/google.png" class="me-2" alt="google" />
                      Sign Up using Google
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="../../../assets/img/icons/facebook.png" class="me-2" alt="google" />
                      Sign Up using Facebook
                    </a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
// import { ref } from "vue";
// import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
// import { router } from "../../../router";
// import VueRouter from "vue-router";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";
import { AlertPopup, CloseLoading, ShowLoading, base_url, timeout, ErrorConnectionTimeOut, goPage } from "@/assets/js/function";


export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      showPasswordConfirm: false,
      password: null,
      passwordconfirm: null,
      phone: "",

      timer: 0,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";

    },
    buttonLabelConfirm() {
      return this.showPasswordConfirm ? "Hide" : "Show";
    }
  },

  // mounted() {
  //   if (localStorage.getItem('SendOTPDate') != null) {
  //     setInterval(this.cekIsSendOTP(), 1000);
  //   }



  // },
  created() {
    const token = Cookies.get("token_refresh");

    if (token) {
      if (token !== 'null' && token.trim() !== '') {

      } else {
        // Token is set but is null or an empty string
        goPage('');
      }
    } else {
      // Token is not set
      goPage('');
    }

  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;
    },

    cekIsSendOTP() {
      let date_now = new Date();
      date_now = date_now.setSeconds(date_now.getSeconds() + 0);
      const date_local = parseInt(localStorage.getItem('SendOTPDate'));

      if (date_local < date_now) {
        localStorage.setItem('isSendOTP', 'true');

      } else {
        this.timer = parseInt(((date_now - date_local) / 1000) * -1);
        localStorage.getItem('isSendOTP', 'false');
      }
    },

    sendOtp() {
      ShowLoading();
      this.cekIsSendOTP();
      if (localStorage.getItem('isSendOTP') == "false") {
        AlertPopup("error", "", "coba lagi dalam " + this.timer + " detik", 2000, false);
        return true;
      }

      let formData = new FormData();

      formData.append("phone", localStorage.getItem('phone'));

      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'v2_lupa_pin';
      } else {
        api = base_url + 'v2_lupa_pin';
      }

      axios
        .post(api, formData, { timeout: timeout })
        .then((response) => {
          if (response.data.status == true) {
            Cookies.set("token_refresh", response.data.token);
            localStorage.setItem("phone", response.data.phone);
            CloseLoading();
            // goPage('confirm-otp');
            AlertPopup("success", "", "OTP has been sent", 2000, false);
            formData.append("phone", localStorage.setItem('isSendOTP', 'false'));

            let currentDate2 = new Date();
            currentDate2 = currentDate2.setSeconds(currentDate2.getSeconds() + 120);
            // console.log(currentDate2);
            localStorage.setItem('SendOTPDate', currentDate2);

          } else {
            // this.showAlert(response.data.message);
            // this.email = "0" + this.email.substring(2);
            // this.isi_button = `<div class="fs-7">Login</div>`;
            AlertPopup("error", "", response.data.message, 2000, false);
          }
        })
        .catch((error) => {
          // this.isi_button = `<div class="fs-7">Login</div>`;

          ErrorConnectionTimeOut(error);
        });
    }

  },
  setup() {
    // let users = localStorage.getItem("storedData");
    // if (users === null) {
    //   let password = [
    //     {
    //       phone: "081234567890",
    //     },
    //   ];
    //   const jsonData = JSON.stringify(password);
    //   localStorage.setItem("storedData", jsonData);
    // }
    const schema = Yup.object().shape({
      password: Yup.string().nullable().required("Password is required"),
      passwordconfirm: Yup.string().nullable().required("Password Confirm is required"),
      // password: Yup.string()

      //   .required("Password is required"),
    });
    const onSubmit = (values) => {
      // localStorage.setItem("phone", values.phone);

      ShowLoading();

      let formData = new FormData();

      formData.append("new_pass", values.password);
      formData.append("confirm_pass", values.passwordconfirm);

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      const token = Cookies.get("token_refresh");
      const config = {
        headers: { Authorization: `Bearer ` + token },
        timeout: 30000,
      }
      // console.log(token);


      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'change_pass';
      } else {
        api = base_url + 'change_pass';
      }

      axios
        .post(api, formData, config)
        .then((response) => {
          if (response.data.status == true) {
            Cookies.set("token_refresh", '');
            Cookies.set("token", '');
            Cookies.set("useremailphone", '');
            CloseLoading();
            goPage("");

            // AlertPopup("success", "", "OTP has been sent", 2000, false);
          } else {
            // this.showAlert(response.data.message);
            // this.email = "0" + this.email.substring(2);
            // this.isi_button = `<div class="fs-7">Login</div>`;
            AlertPopup("error", "", response.data.message, 2000, false);
          }
        })
        .catch((error) => {
          // this.isi_button = `<div class="fs-7">Login</div>`;

          ErrorConnectionTimeOut(error);
        });

    };
    return {
      schema,
      onSubmit,
    };
  },
};
</script>
