<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nama Lengkap<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.fullname" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No NPWP (15/16 Digit) <span class="text-danger" for="npwp">*</span></label>
                    <input type="text" v-model="currentData.npwp" maxlength="16" minlength="15" name="npwp" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" v-model="currentData.email" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No Telepon<span class="text-danger">*</span></label>
                    <input type="tel" v-model="currentData.telepone" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No Whatsapp 1<span class="text-danger">*</span></label>
                    <input type="tel" v-model="currentData.whatsapp" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No Whatsapp 2<span class="text-danger">*</span></label>
                    <input type="tel" v-model="currentData.whatsapp_2" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Lahir </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.dob" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Jabatan</label>
                    <input type="text" v-model="currentData.job_title" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Perusahaan</label>
                    <input type="text" v-model="currentData.company" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Bidang Industri</label>
                    <input type="text" v-model="currentData.industry" />
                  </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Ketertarikan</label>
                    <input type="text" v-model="currentData.interest" />
                  </div>
                </div> -->
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Kategori Pelanggan</label>
                    <model-select :options="needData1" placeholder="Pilih Kategori Pelanggan"
                      v-model="currentData.id_kategori_pelanggan">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Agama<span class="text-danger">*</span></label>
                    <vue-select :options="agamaList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.agama" placeholder="PILIH AGAMA" required />
                  </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Jumlah Diskon (%)</label>
                    <input type="text" v-model="currentData.discount_rate" />
                  </div>
                </div> -->
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tingkatan Harga<span class="text-danger">*</span></label>
                    <vue-select :options="pricingList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.rate" placeholder="None" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Metode Pembayaran</label>
                    <model-select :options="needData2" placeholder="Pilih Metode Pembayaran"
                      v-model="currentData.id_payment">
                    </model-select>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nominal Limit Kredit</label>
                    <input type="text" @input="formatCurrency('limit_kredit')" v-model="currentData.limit_kredit" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Batas Hari Limit Kredit</label>
                    <input type="number" v-model="currentData.limit_kredit_hari" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Ekspedisi <span class="text-secondary">Pilih Provinsi terlebih dahulu</span></label>
                    <model-select :options="ekspedisiList" placeholder="Pilih Ekspedisi"
                      v-model="currentData.id_ekspedisi">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Kode POS</label>
                    <input type="number" v-model="currentData.kode_pos" />
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Alamat</label>
                    </div>

                    <model-select :options="provinsiList" placeholder="Pilih Provinsi" class="mb-3"
                      v-model="currentData.provinsi" required>
                    </model-select>
                    <model-select :options="kotaList" placeholder="Pilih Kota/Kabupaten" class="mb-3"
                      v-model="currentData.kota" required>
                    </model-select>
                    <model-select :options="kecamatanList" placeholder="Pilih Kecamatan" class="mb-3"
                      v-model="currentData.kecamatan" required>
                    </model-select>
                    <model-select :options="kelurahanList" placeholder="Pilih Kelurahan" class="mb-3"
                      v-model="currentData.kelurahan" required>
                    </model-select>
                    <textarea type="text" class="mb-3" v-model="currentData.alamat" placeholder="Masukkan Alamat" />
                  </div>
                </div>
                <!-- <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Alamat Penagihan</label>
                    <input type="text" class="mb-1" v-model="currentData.billing_address1" />
                    <input type="text" class="mb-1" v-model="currentData.billing_address2" />
                    <input type="text" class="mb-1" v-model="currentData.billing_address3" />
                  </div>



                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Alamat Pengiriman - [<a href="javascript:void(0);" @click="copyFromBillingAddres()">salin
                        dari
                        Alamat Penagihan</a>]</label>

                    <input type="text" class="mb-1" v-model="currentData.shipping_address1" />
                    <input type="text" class="mb-1" v-model="currentData.shipping_address2" />
                    <input type="text" class="mb-1" v-model="currentData.shipping_address3" />
                  </div>

                </div> -->
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="customer" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, agamaList, base_url_wilayah_indonesia, AlertBottomError, rupiah, rupiahInput, rupiahoutput } from "@/assets/js/function";
import { ModelSelect } from "vue-search-select";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editcustomer",
  data() {
    return {
      filter: true,
      title: "Edit Pelanggan",
      title1: "edit pelanggan data",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentData: {
        fullname: "",
        email: "",
        telepone: "",
        whatsapp: "",
        job_title: "",
        dob: "",
        rate: 0,
        discount_rate: 0,
        company: "",
        industry: "",
        interest: "",
        billing_address1: "",
        billing_address2: "",
        billing_address3: "",
        shipping_address1: "",
        shipping_address2: "",
        shipping_address3: "",
        shipping_name: "",
        default_terms: 0,


      },
      pricingList: [
        {
          "id": "0",
          "text": "Agen Khusus"
        },
        {
          "id": "2",
          "text": "Agen Umum"
        },
        {
          "id": "3",
          "text": "Supplier"
        },
        {
          "id": "4",
          "text": "E-Commerce"
        },
        {
          "id": "5",
          "text": "Retail"
        },
        {
          "id": "6",
          "text": "End User"
        },
      ],
      currentDate: new Date,

      //data
      needData1: [],
      needData2: [],
      needData3: [],
      agamaList: agamaList,

      provinsiList: [],
      kotaList: [],
      kecamatanList: [],
      kelurahanList: [],
      status_update_alamat: 0,
      ekspedisiList: [],
    };
  },

  created() {
    this.initializeData();
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  watch: {
    'currentData.provinsi': {
      handler(newValue) {
        this.kabupaten_index(newValue);
        if (this.status_update_alamat > 0) {
          this.currentData.kota = '';
          this.currentData.kecamatan = '';
          this.currentData.kelurahan = '';
        }


        const index = this.provinsiList.findIndex((item) => item.value == newValue);

        if (index >= 0) {
          this.ekspedisiList = this.needData3.filter(
            item =>
              item.provinsi.toLowerCase().includes(this.provinsiList[index].text.toLowerCase())
          );

        }


      }
    },
    'currentData.kota': {
      handler(newValue) {
        this.kecamatan_index(newValue);
        if (this.status_update_alamat > 0) {
          this.currentData.kecamatan = '';
          this.currentData.kelurahan = '';
        }
      }
    },
    'currentData.kecamatan': {
      handler(newValue) {
        this.kelurahan_index(newValue);
        if (this.status_update_alamat > 0) {
          this.currentData.kelurahan = '';
        }
      }
    },
    'currentData.id_ekspedisi': {
      handler(newValue) {
        if (this.currentData.provinsi == '' || this.currentData.provinsi == undefined) {
          AlertPopup("error", "", "Silahkan Isi Provinsi terlebih dahulu", 1500, false);
          this.currentData.id_ekspedisi = '';
        }
      }
    },
  },
  methods: {
    formatCurrency(name) {
      this.currentData[name] = rupiahInput(this.currentData[name]);
    },
    copyFromBillingAddres() {

      this.currentData.shipping_address1 = this.currentData.billing_address1;
      this.currentData.shipping_address2 = this.currentData.billing_address2;
      this.currentData.shipping_address3 = this.currentData.billing_address3;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'customer_update';
      } else {
        this.api = base_url + 'customer_update';
      }

      formData.delete("dob");

      if (this.currentData.dob != 'Invalid Date' && this.currentData.dob != new Date()) {
        var formattedDate = this.currentData.dob.toISOString().split('T')[0];
        formData.append("dob", formattedDate);
      } else {
        formData.append("dob", null);
      }

      //provinsi 
      formData.delete("provinsi");
      const index_provinsi = this.provinsiList.findIndex((record) => record.value === this.currentData.provinsi);
      if (index_provinsi > -1) {
        formData.append('provinsi', this.provinsiList[index_provinsi].text);
      }

      //kota
      formData.delete("kota");
      const index_kota = this.kotaList.findIndex((record) => record.value === this.currentData.kota);
      if (index_kota > -1) {
        formData.append('kota', this.kotaList[index_kota].text);
      }
      //kabupaten
      formData.delete("kecamatan");
      const index_kecamatan = this.kecamatanList.findIndex((record) => record.value === this.currentData.kecamatan);
      if (index_kecamatan > -1) {
        formData.append('kecamatan', this.kecamatanList[index_kecamatan].text);
      }

      //kelurahan
      formData.delete("kelurahan");
      const index_kelurahan = this.kelurahanList.findIndex((record) => record.value === this.currentData.kelurahan);
      if (index_kelurahan > -1) {
        formData.append('kelurahan', this.kelurahanList[index_kelurahan].text);
      }

      formData.delete("limit_kredit");
      formData.append("limit_kredit", rupiahoutput(this.currentData.limit_kredit));

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("customer");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    async initializeData() {
      const token = Cookies.get("token_refresh");

      this.config = {
        headers: {
          Authorization: `Bearer ` + token,
        },
        timeout: 30000,
      }

      //get data
      this.currentData = JSON.parse(localStorage.getItem('currentData'));
      this.currentData.dob = new Date(this.currentData.dob);
      this.currentData.limit_kredit = rupiahInput(this.currentData.limit_kredit);

      // Get alamat
      // Panggil api provinsi 
      await this.provinsi_index();
      this.prepareData();

      if (this.currentData.provinsi != null && this.currentData.provinsi != '') {
        const index_provinsi = this.provinsiList.findIndex((record) => record.text.toLowerCase() == this.currentData.provinsi.toLowerCase());
        this.currentData.provinsi = this.provinsiList[index_provinsi].value;
        await this.kabupaten_index(this.currentData.provinsi);
        const index_kota = this.kotaList.findIndex((record) => record.text.toLowerCase() == this.currentData.kota.toLowerCase());
        if (index_kota >= 0) {
          this.currentData.kota = this.kotaList[index_kota].value;
          await this.kecamatan_index(this.currentData.kota);
          const index_kecamatan = this.kecamatanList.findIndex((record) => record.text.toLowerCase() == this.currentData.kecamatan.toLowerCase());
          if (index_kecamatan >= 0) {
            this.currentData.kecamatan = this.kecamatanList[index_kecamatan].value;
            await this.kelurahan_index(this.currentData.kecamatan);
            const index_kelurahan = this.kelurahanList.findIndex((record) => record.text.toLowerCase() == this.currentData.kelurahan.toLowerCase());
            if (index_kelurahan >= 0) {
              this.currentData.kelurahan = this.kelurahanList[index_kelurahan].value;
              this.status_update_alamat = 1;
            }

          }

        }

      }


    },
    async provinsi_index() {
      this.provinsiList = [];
      ShowLoading();
      try {
        const response = await axios.post(base_url_wilayah_indonesia + "provinsi_index", '');
        if (response.data.status === true) {
          response.data.data.forEach((value) => {
            let item_prepare = { value: value.id, text: value.nama };
            this.provinsiList.push(item_prepare);
          });
        } else if (response.data.status == false) {
          console.log(response.data);
          AlertBottomError(response.data.message ? response.data.message : '');

        }
      } catch (error) {
        ErrorConnectionTimeOut(error);
      } finally {
        CloseLoading();
      }
    },
    async kabupaten_index(id) {
      this.kotaList = [];
      ShowLoading();
      try {
        const formData = new FormData();
        formData.append("id", id);
        const response = await axios.post(base_url_wilayah_indonesia + "kabupaten_index", formData);

        if (response.data.status === true && response.data.data != null) {
          response.data.data.forEach((value) => {
            let item_prepare = { value: value.id, text: value.nama };
            this.kotaList.push(item_prepare);
          });
        } else if (response.data.status == false) {
          AlertBottomError(response.data.message ? response.data.message : '');

        }
      } catch (error) {
        ErrorConnectionTimeOut(error);
      } finally {
        CloseLoading();
      }
    },
    async kecamatan_index(id) {
      this.kecamatanList = [];
      ShowLoading();
      try {
        const formData = new FormData();
        formData.append("id", id);
        const response = await axios.post(base_url_wilayah_indonesia + "kecamatan_index", formData);
        if (response.data.status === true && response.data.data != null) {
          response.data.data.forEach((value) => {
            let item_prepare = { value: value.id, text: value.nama };
            this.kecamatanList.push(item_prepare);
          });
        } else if (response.data.status == false) {
          AlertBottomError(response.data.message ? response.data.message : '');

        }
      } catch (error) {
        ErrorConnectionTimeOut(error);
      } finally {
        CloseLoading();
      }
    },
    async kelurahan_index(id) {
      this.kelurahanList = [];
      ShowLoading();
      try {
        const formData = new FormData();
        formData.append("id", id);
        const response = await axios.post(base_url_wilayah_indonesia + "kelurahan_index", formData);
        if (response.data.status === true && response.data.data != null) {
          response.data.data.forEach((value) => {
            let item_prepare = { value: value.id, text: value.nama };
            this.kelurahanList.push(item_prepare);
          });
        } else if (response.data.status == false) {
          AlertBottomError(response.data.message ? response.data.message : '');

        }
      } catch (error) {
        ErrorConnectionTimeOut(error);
      } finally {
        CloseLoading();
      }
    },
    prepareData() {
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      needData1.forEach((value, index) => {
        let item_prepare = { value: value.id, text: value.nama };
        this.needData1.push(item_prepare)
      });

      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((value, index) => {
        let item_prepare = { value: value.id, text: value.nama };
        this.needData2.push(item_prepare)
      });


      const needData3 = JSON.parse(localStorage.getItem('needData3'));
      needData3.forEach((value, index) => {
        let item_prepare = { value: value.id, text: value.nama, provinsi: value.provinsi };
        this.needData3.push(item_prepare)
      });
    }

  },
  components: {
    ModelSelect
  },

};
</script>
