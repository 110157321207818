<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nama <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.fullname" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No NPWP (15/16 Digit) <span class="text-danger" for="npwp">*</span></label>
                    <input type="text" v-model="currentData.npwp" maxlength="16" minlength="15" name="npwp" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" v-model="currentData.email" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No Telepon<span class="text-danger">*</span></label>
                    <input type="tel" v-model="currentData.telepone" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nama Lengkap PIC 1<span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.fullname_pic_1" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Whatsapp PIC 1<span class="text-danger">*</span></label>
                    <input type="tel" v-model="currentData.whatsapp_pic_1" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nama Lengkap PIC 2</label>
                    <input type="text" v-model="currentData.fullname_pic_2" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Whatsapp PIC 2</label>
                    <input type="tel" v-model="currentData.whatsapp_pic_2" />
                  </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Tanggal Lahir</label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker class="picker" :editable="true" :clearable="false" v-model="currentData.dob" />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Jabatan</label>
                    <input type="text" v-model="currentData.job_title" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Perusahaan</label>
                    <input type="text" v-model="currentData.company" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>bidang industri</label>
                    <input type="text" v-model="currentData.industry" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nama Bank 1</label>
                    <model-select :options="needData1" placeholder="Pilih Jenis Bank" v-model="currentData.id_bank_1">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No Rek 1</label>
                    <input type="text" v-model="currentData.no_rek_1" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Nama Bank 2</label>
                    <model-select :options="needData1" placeholder="Pilih Jenis Bank" v-model="currentData.id_bank_2">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>No Rek 2</label>
                    <input type="text" v-model="currentData.no_rek_2" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Metode Pembayaran</label>
                    <model-select :options="needData2" placeholder="Pilih Metode Pembayaran"
                      v-model="currentData.id_payment">
                    </model-select>
                  </div>
                </div>




                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Jumlah Hari Jatuh Tempo</label>
                    <input type="number" v-model="currentData.jumlah_hari_jatuh_tempo" />
                  </div>
                </div> -->

                <div class="col-lg-3 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Kode POS</label>
                    <input type="number" v-model="currentData.kode_pos" />
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Alamat</label>
                    </div>
                    <!-- <vue-select :options="satuanList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.satuan_berat" placeholder="TIDAK ADA">
                    </vue-select> -->
                    <model-select :options="provinsiList" placeholder="Pilih Provinsi" class="mb-3"
                      v-model="currentData.provinsi" required>
                    </model-select>
                    <model-select :options="kotaList" placeholder="Pilih Kota/Kabupaten" class="mb-3"
                      v-model="currentData.kota" required>
                    </model-select>
                    <model-select :options="kecamatanList" placeholder="Pilih Kecamatan" class="mb-3"
                      v-model="currentData.kecamatan" required>
                    </model-select>
                    <model-select :options="kelurahanList" placeholder="Pilih Kelurahan" class="mb-3"
                      v-model="currentData.kelurahan" required>
                    </model-select>
                    <textarea type="text" class="mb-3" v-model="currentData.alamat" placeholder="Masukkan Alamat" />
                  </div>
                </div>
                <!-- <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Kota / Kabupaten</label>
                    <input type="text" class="mb-1" v-model="currentData.kota" />
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Kecamatan</label>
                    <input type="text" class="mb-1" v-model="currentData.kecamatan" />
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Kelurahan</label>
                    <input type="text" class="mb-1" v-model="currentData.kelurahan" />
                  </div>
                </div> -->

                <!-- <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Alamat Penagihan</label>
                    <input type="text" class="mb-1" v-model="currentData.billing_address1" />
                    <input type="text" class="mb-1" v-model="currentData.billing_address2" />
                    <input type="text" class="mb-1" v-model="currentData.billing_address3" />
                  </div>


                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Alamat Pengiriman - [<a href="javascript:void(0);" @click="copyFromBillingAddres()">salin
                        dari
                        Alamat Penagihan</a>]</label>

                    <input type="text" class="mb-1" v-model="currentData.shipping_address1" />
                    <input type="text" class="mb-1" v-model="currentData.shipping_address2" />
                    <input type="text" class="mb-1" v-model="currentData.shipping_address3" />
                  </div>

                </div> -->
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Tambah</button>
                  <router-link to="ekspedisi" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, base_url_wilayah_indonesia, AlertBottomError } from "@/assets/js/function";
import { ModelSelect } from "vue-search-select";
// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editcustomer",
  data() {
    return {
      filter: true,
      title: "Tambah Ekspedisi",
      title1: "tambah ekspedisi baru",
      config: "",
      api: "",

      //data
      currentData: {
        fullname: "",
        email: "",
        telepone: "",
        whatsapp: "",
        job_title: "",
        dob: "",
        rate: 0,
        discount_rate: 0,
        company: "",
        industry: "",
        interest: "",
        billing_address1: "",
        billing_address2: "",
        billing_address3: "",
        shipping_address1: "",
        shipping_address2: "",
        shipping_address3: "",
        shipping_name: "",
        default_terms: 0,
        callback: "customers",

      },

      currentDate: new Date,

      provinsiList: [],
      kotaList: [],
      kecamatanList: [],
      kelurahanList: [],

      needData1: [],
      needData2: [],
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.currentData.dob = new Date();
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }
    if (localStorage.getItem('callback') != null) {
      this.callback = localStorage.getItem('callback');
    }

    //panggil api provinsi 
    this.provinsi_index();


    this.prepareData();


  },

  mounted() {
    if (localStorage.getItem('callback') != null) {
      localStorage.removeItem('callback');
    }
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  watch: {
    'currentData.provinsi': {
      handler(newValue) {
        this.kabupaten_index(newValue);
        this.currentData.kota = '';
        this.currentData.kecamatan = '';
        this.currentData.kelurahan = '';
      }
    },
    'currentData.kota': {
      handler(newValue) {
        this.kecamatan_index(newValue);
        this.currentData.kecamatan = '';
        this.currentData.kelurahan = '';
      }
    },
    'currentData.kecamatan': {
      handler(newValue) {
        this.kelurahan_index(newValue);
        this.currentData.kelurahan = '';
      }
    },
  },
  methods: {
    provinsi_index() {
      this.provinsiList = [];
      ShowLoading();
      axios
        .post(base_url_wilayah_indonesia + "provinsi_index", '')
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopup("success", response.data.message, "", 1500, false);
            // console.log(response.data.data);
            response.data.data.forEach((value, index) => {
              let item_prepare = { value: value.id, text: value.nama };
              this.provinsiList.push(item_prepare)
            });

          }
          if (response.data.status == false) {

            AlertBottomError(response.data.message ? response.data.message : '');

          }
          CloseLoading();
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kabupaten_index(id) {
      this.kotaList = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      axios
        .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
        .then((response) => {

          if (response.data.status == true && response.data.data != null) {

            response.data.data.forEach((value, index) => {
              let item_prepare = { value: value.id, text: value.nama };
              this.kotaList.push(item_prepare)
            });


          }
          if (response.data.status == false) {

            AlertBottomError(response.data.message ? response.data.message : '');


          }
          CloseLoading();
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kecamatan_index(id) {
      this.kecamatanList = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
        .then((response) => {
          if (response.data.status == true && response.data.data != null) {
            response.data.data.forEach((value, index) => {
              let item_prepare = { value: value.id, text: value.nama };
              this.kecamatanList.push(item_prepare)
            });


          }
          if (response.data.status == false) {
            AlertBottomError(response.data.message ? response.data.message : '');


          }
          CloseLoading();
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kelurahan_index(id) {
      this.kelurahanList = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
        .then((response) => {
          if (response.data.status == true && response.data.data != null) {
            response.data.data.forEach((value, index) => {
              let item_prepare = { value: value.id, text: value.nama };
              this.kelurahanList.push(item_prepare)
            });

          }
          if (response.data.status == false) {
            AlertBottomError(response.data.message ? response.data.message : '');


          }
          CloseLoading();
        })

        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });

    },
    // copyFromBillingAddres() {

    //   this.currentData.shipping_address1 = this.currentData.billing_address1;
    //   this.currentData.shipping_address2 = this.currentData.billing_address2;
    //   this.currentData.shipping_address3 = this.currentData.billing_address3;
    // },
    prepareData() {

      // needData1
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      needData1.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        this.needData1.push(element);
      });

      // needData2
      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        this.needData2.push(element);
      });


    },
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete("dob");
      if (this.currentData.dob != 'Invalid Date' && this.currentData.dob != new Date()) {
        var formattedDate = this.currentData.dob.toISOString().split('T')[0];
        formData.append("dob", formattedDate);
      } else {
        formData.append("dob", null);
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'ekspedisi_insert';
      } else {
        this.api = base_url + 'ekspedisi_insert';
      }

      //provinsi 
      formData.delete("provinsi");
      const index_provinsi = this.provinsiList.findIndex((record) => record.value === this.currentData.provinsi);
      if (index_provinsi > -1) {
        formData.append('provinsi', this.provinsiList[index_provinsi].text);
      }

      //kota
      formData.delete("kota");
      const index_kota = this.kotaList.findIndex((record) => record.value === this.currentData.kota);
      if (index_kota > -1) {
        formData.append('kota', this.kotaList[index_kota].text);
      }
      //kabupaten
      formData.delete("kecamatan");
      const index_kecamatan = this.kecamatanList.findIndex((record) => record.value === this.currentData.kecamatan);
      if (index_kecamatan > -1) {
        formData.append('kecamatan', this.kecamatanList[index_kecamatan].text);
      }

      //kelurahan
      formData.delete("kelurahan");
      const index_kelurahan = this.kelurahanList.findIndex((record) => record.value === this.currentData.kelurahan);
      if (index_kelurahan > -1) {
        formData.append('kelurahan', this.kelurahanList[index_kelurahan].text);
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage('ekspedisi');
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },
  components: {
    ModelSelect
  },

};
</script>
