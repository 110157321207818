<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <div class="row" v-if="tipe == 'ADMIN'">
                    <!-- Chart -->
                    <!-- <div class="col-12 gap-2  d-flex align-items-start justify-content-end">
                        <div class="d-flex flex-column justify-content-center align-items-end pt-1 ">
                            <a href="javascript:void(0);" @click="exportExcel()"><img
                                    src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                            <span style="font-size: 10px; margin-top: -8px;">excel</span>
                        </div>
                        <div class="">
                            <div class="form-group">
                                
                                <div class="input-groupicon" style="z-index: 100;">
                                    <datepicker v-model="currentData.dfrom" class="picker" :editable="true"
                                        :clearable="false" required />
                                    <a class="addonset">
                                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="form-group">
                                
                                <div class="input-groupicon" style="z-index: 100;">
                                    <datepicker v-model="currentData.dto" class="picker" :editable="true"
                                        :clearable="false" required />
                                    <a class="addonset">
                                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <button class="btn btn-sm btn-primary " @click="index"> <vue-feather
                                    type="filter"></vue-feather></button>
                        </div>
                    </div> -->
                    <!-- upper limit -->
                    <!-- <pageheader title="Project" title1="" />
                    <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                           
                    <div class="dash-widgetcontent">
                        <h5>
                            Upcoming Project
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_project_pending)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_project_pending)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            Ongoing Project
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_project_berjalan)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_project_berjalan)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            Outstanding Project
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_project_outstanding)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_project_outstanding)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            Finished Project
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_project_selesai)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_project_selesai)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                        </div>

                    </div>
                </div>
            </div>

            <pageheader title="Trip" title1="" />
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                    
                    <div class="dash-widgetcontent">
                        <h5>
                            Upcoming Trip
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_trip_pending)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_trip_pending)" :duration="2"
                                    :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            Ongoing Trip
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_trip_berjalan)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_trip_berjalan)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                  
                    <div class="dash-widgetcontent">
                        <h5>
                            Outstanding Trip
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_trip_outstanding)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_trip_outstanding)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            Finished Trip
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_trip_selesai)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_trip_selesai)" :duration="2"
                                    :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                        </div>

                    </div>
                </div>
            </div>
            <pageheader title="Lain-lain" title1="" />
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                    
                    <div class="dash-widgetcontent">
                        <h5>
                            Maintenance / Repairs
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_maintenance)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_maintenance)" :duration="2"
                                    :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                  
                    <div class="dash-widgetcontent">
                        <h5>
                            STNK Expiring
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_asset_stnk_expired)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_asset_stnk_expired)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            SIM Expiring
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_driver)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_sim_expired)" :duration="2"
                                    :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> supir</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                    
                    <div class="dash-widgetcontent">
                        <h5>
                            Uji KIR Expiring
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_asset_kir_expired)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_asset_kir_expired)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                        </div>

                    </div>
                </div>
            </div> -->
                    <!-- lower limit dashboard acs -->
                    <!-- <div class="col-lg-4 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div>
                            <div class="dash-widgetcontent">
                                <h5>
                                    IDR <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.total_deposit)" :duration="2"
                                        :autoinit="true" />
                                </h5>
                                <h6>Total Deposit</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div>
                            <div class="dash-widgetcontent">
                                <h5>
                                    IDR <vue3-autocounter class="counter" ref="counter" :startAmount="0" :delay="3"
                                        :endAmount="parseInt(resultData.total_sales_fuqi)" :duration="2"
                                        :autoinit="true" />
                                </h5>
                                <h6>Total Nett Sales</h6>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-lg-6 col-sm-6 col-12 d-flex">
                        <div class="dash-count">
                            <div class="dash-counts">
                                <h4>{{ parseInt(resultData.total_customer) }}</h4>
                                <h5>Customers</h5>
                            </div>
                            <div class="dash-imgs">
                                <vue-feather type="user"></vue-feather>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12 d-flex">
                        <div class="dash-count das3">
                            <div class="dash-counts">
                                <h4>{{ parseInt(resultData.total_invoices) }}</h4>
                                <h5>Sales Invoice</h5>
                            </div>
                            <div class="dash-imgs">
                                <vue-feather type="file"></vue-feather>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Sales
                                    <span v-if="resultData.year_sales.length != 0">{{
                    resultData.year_sales[0].year }}</span>
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="barchartrender">
                                    <vue3-chart-js class="chartjs-chart" v-if="resultData != undefined"
                                        v-bind="{ ...barchart }"></vue3-chart-js>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title ">Best Seller Product
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="barchart2render == true">
                                    <vue3-chart-js class="chartjs-chart" v-bind="{ ...barchart2 }"></vue3-chart-js>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Pyament Method</div>
                            </div>
                            <div class="card-body">
                                <div v-if="barchart3render == true">
                                    <vue3-chart-js class="chartjs-chart" v-bind="{ ...barchart3 }"></vue3-chart-js>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title">Grafik Jumlah Penjualan Item</h5>
                            </div>
                            <div class="card-body">
                                <apexchart type="bar" height="350" :options="sbarAreaChart.sbar"
                                    :series="sbarAreaChart.series"></apexchart>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title">Grafik Metode Payment </h5>
                            </div>
                            <div class="card-body">
                                <apexchart type="bar" height="350" :options="sbarAreaChart2.sbar"
                                    :series="sbarAreaChart2.series"></apexchart>
                            </div>
                        </div>
                    </div> -->

                </div>



            </div>
        </div>
    </div>
</template>

<style scoped>
.card-title {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.card-header {
    padding-bottom: 0px !important;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right {

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* background-color: red; */
}
</style>

<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, openInNewTab } from "@/assets/js/function";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
    data() {
        return {


            companyList: [],
            config: "",
            api: "",
            showChart: false,

            //data
            currentData: { dfrom: null, dto: null },
            resultData: {
                dashboard: {
                    total_deposit: 0,
                    total_customer: 0,
                    total_deposit
                        :
                        0,
                    total_invoices
                        :
                        0,
                    total_sales
                        :
                        0,
                    total_sales_fuqi
                        :
                        0,
                    jumlah_project_pending: 0,
                    persentasi_project_pending: 0,
                    jumlah_project_berjalan: 0,
                    persentasi_project_berjalan: 0,
                    jumlah_project_outstanding: 0,
                    persentasi_project_outstanding: 0,
                    jumlah_project_selesai: 0,
                    persentasi_project_selesai: 0,
                    jumlah_trip_pending: 0,
                    persentasi_trip_pending: 0,
                    jumlah_trip_berjalan: 0,
                    persentasi_trip_berjalan: 0,
                    jumlah_trip_outstanding: 0,
                    persentasi_trip_outstanding: 0,
                    jumlah_trip_selesai: 0,
                    persentasi_trip_selesai: 0,
                    jumlah_maintenance: 0,
                    persentasi_maintenance: 0,
                    jumlah_asset_stnk_expired: 0,
                    persentasi_asset_stnk_expired: 0,
                    jumlah_driver: 0,
                    persentasi_sim_expired: 0,
                    jumlah_asset_kir_expired: 0,
                    persentasi_asset_kir_expired: 0,
                },
                year_sales: [
                    {
                        year: "",
                        month: "Mar",
                        sales_amount: "11000000.00"
                    },

                ]
            },
            //token
            token: "",

            barchart: {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            barchart2: {

                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    indexAxis: "y",
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            barchart3: {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    indexAxis: "y",
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            // sbarAreaChart: {

            //     sbar: {
            //         chart: {
            //             id: "vuechart-example",
            //         },

            //         chart: {
            //             type: 'bar',
            //             height: 350,
            //             stacked: true,
            //             toolbar: {
            //                 show: false,
            //             }
            //         },
            //         plotOptions: {
            //             bar: {
            //                 horizontal: true,
            //             }
            //         },
            //         dataLabels: {
            //             enabled: false
            //         },
            //         xaxis: {
            //             categories: [],
            //         }

            //     },

            //     series: [{
            //         name: "Jumlah",
            //         data: []
            //     }],

            // },
            // sbarAreaChart2: {

            //     sbar: {
            //         chart: {
            //             id: "vuechart-example",
            //         },

            //         chart: {
            //             type: 'bar',
            //             height: 350,
            //             stacked: true,
            //             toolbar: {
            //                 show: false,
            //             }
            //         },
            //         plotOptions: {
            //             bar: {
            //                 horizontal: true,
            //             }
            //         },
            //         dataLabels: {
            //             enabled: false
            //         },
            //         xaxis: {
            //             categories: [],
            //         },
            //         yaxis: {
            //             reversed: false,
            //         }

            //     },

            //     series: [{
            //         name: "Total",
            //         colors: ['#FFB55C'],
            //         data: []
            //     }],

            // },

            //tipe user 
            tipe: "",
            barchartrender: false,
            barchart2render: false,
            barchart3render: false,

        };
    },
    components: {
        Vue3ChartJs,
    },

    methods: {
        exportExcel() {
            this.index();

            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                // Create a new Date object
                const date = new Date(value);

                // Use built-in methods to get year, month, and day
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                formData.append(key, formattedDate);
            });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_export';
            } else {
                this.api = base_url + 'dashboard_export';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        openInNewTab(response.data.data.file_report);
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        index() {
            ShowLoading();
            // this.barchart2render = false;
            // this.barchart3render = false;
            // this.barchartrender = false;
            let formData = new FormData();

            // Object.entries(this.currentData).forEach(([key, value]) => {
            //     // Create a new Date object
            //     const date = new Date(value);

            //     // Use built-in methods to get year, month, and day
            //     const year = date.getFullYear();
            //     const month = String(date.getMonth() + 1).padStart(2, '0');
            //     const day = String(date.getDate()).padStart(2, '0');
            //     const formattedDate = `${year}-${month}-${day}`;
            //     formData.append(key, formattedDate);
            // });

            // for (let [key, value] of formData.entries()) {

            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard';
            } else {
                this.api = base_url + 'dashboard';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {

                        this.resultData = response.data.data;



                        // this.barchartrender = true;
                        // this.barchart.data.labels = [];
                        // this.barchart.data.datasets[0].data = [];

                        // this.barchart2render = true;
                        // this.barchart2.data.labels = [];
                        // this.barchart2.data.datasets[0].data = [];

                        // this.barchart3render = true;
                        // this.barchart3.data.labels = [];
                        // this.barchart3.data.datasets[0].data = [];

                        // response.data.data.year_sales.forEach((item) => {
                        //     this.barchart.data.labels.push(item.month)
                        //     this.barchart.data.datasets[0].data.push(parseInt(item.sales_amount))
                        // });



                        // response.data.data.best_seller.forEach((item) => {
                        //     this.barchart2.data.labels.push(item.item_name)
                        //     this.barchart2.data.datasets[0].data.push(parseInt(item.item_total))
                        // });


                        // response.data.data.payment.forEach((item) => {
                        //     this.barchart3.data.labels.push(item.payment_method)
                        //     this.barchart3.data.datasets[0].data.push(parseInt(item.payment_amount))
                        // });





                        CloseLoading();
                    }



                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },




    },

    mounted() {
        let currentDate = new Date();

        // First day of the month
        let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        // Last day of the month
        let lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        // Assigning to data object
        this.currentData.dfrom = currentDate;
        this.currentData.dto = currentDate;
        // this.index();
        this.tipe = localStorage.getItem('tipe_user');

    },

    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
        // this.index();

        // this.dashboard_notif();
        // this.dashboard_index();
    }


};
</script>
