<template>
  <!-- Header -->
  <div class="header">
    <headerlogo1 />

    <a id="mobile_btn" class="mobile_btn" href="#sidebar">
      <span class="bar-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </a>

    <headermenu :nama_user="nama_user" :tipe_user="tipe_user" :gambar_user="gambar_user" />

    <mobilemenu />
  </div>
  <!-- Header -->
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, goPage, refreshPage } from "@/assets/js/function";

export default {
  data() {
    return {
      // nama_user: "",
      // tipe_user: "",
      nama_user: "",
      tipe_user: "",
      gambar_user: "",
      versi: "",
      need_update: false,
    }
  },
  components: {},
  methods: {
    updateVersi() {
      localStorage.setItem('versi', this.versi)
      refreshPage();
    },
    cekAuth() {
      ShowLoading();

      // let formData = new FormData();
      // formData.append("", 'pengajuan');
      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'check_auth';
      } else {
        api = base_url + 'check_auth';
      }


      const config = {
        headers: {
          'Authorization': `Bearer ` + Cookies.get("token_refresh"),
          'Content-Type': 'application/json',
        },
        timeout: 30000,
      }

      axios
        .post(api, '', config)
        .then((response) => {
          if (response.data.status == true) {

            this.nama_user = response.data.data_users.fullname;
            this.tipe_user = response.data.data_users.tipe;
            this.gambar_user = response.data.data_users.foto;
            localStorage.setItem('tipe_user', response.data.data_users.tipe);
            this.versi = response.data.versi;

            if (localStorage.getItem('versi') != response.data.versi) {
              this.need_update = true;
              this.updateVersi();
            }

            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },
  mounted() {

    // this.nama_user = localStorage.getItem('nama_user');
    // this.tipe_user = localStorage.getItem('tipe_user');

    var $wrapper = $(".main-wrapper");
    $(window).resize(function () {
      if ($(".page-wrapper").length > 0) {
        var height = $(window).height();
        $(".page-wrapper").css("min-height", height);
      }
    });
    $("body").append('<div class="sidebar-overlay"></div>');
    $(document).on("click", "#mobile_btn", function () {
      $wrapper.toggleClass("slide-nav");
      $(".sidebar-overlay").toggleClass("opened");
      $("html").addClass("menu-opened");
      $("#task_window").removeClass("opened");
      return false;
    });

    $(document).on("click", "#toggle_btn", function () {
      if ($("body").hasClass("mini-sidebar")) {
        $("body").removeClass("mini-sidebar");
        $(this).addClass("active");
        $(".subdrop + ul");
        localStorage.setItem("screenModeNightTokenState", "night");
        setTimeout(function () {
          $("body").removeClass("mini-sidebar");
          $(".header-left").addClass("active");
        }, 100);
      } else {
        $("body").addClass("mini-sidebar");
        $(this).removeClass("active");
        $(".subdrop + ul");
        localStorage.removeItem("screenModeNightTokenState", "night");
        setTimeout(function () {
          $("body").addClass("mini-sidebar");
          $(".header-left").removeClass("active");
        }, 100);
      }
      return false;
    });
    $(document).on("mouseover", function (e) {
      e.stopPropagation();
      if ($("body").hasClass("mini-sidebar") && $("#toggle_btn").is(":visible")) {
        var targ = $(e.target).closest(".sidebar, .header-left").length;
        if (targ) {
          $("body").addClass("expand-menu");
          $(".subdrop + ul").slideDown();
        } else {
          $("body").removeClass("expand-menu");
          $(".subdrop + ul").slideUp();
        }
        return false;
      }
    });
    if (localStorage.getItem("screenModeNightTokenState") == "night") {
      setTimeout(function () {
        $("body").removeClass("mini-sidebar");
        $(".header-left").addClass("active");
      }, 100);
    }

    $(".submenus").on("click", function () {
      $("body").addClass("sidebarrightmenu");
    });
  },
  created() {
    this.cekAuth();

  }
};
</script>
