<template>
    <form @submit.prevent="additem">
        <div class="modal fade" id="modaladdproductwarehouse" tabindex="-1" aria-labelledby="modaladdproductwarehouse"
            aria-hidden="true">

            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title">{{ title }}
                            </h5>
                            <span for=""> {{ sub }} </span>
                        </div>

                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                            ref="buttonclosemodaladdproductwarehouse">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- <div class="page-header">
                            <div class="page-title">
                                <h4>Categories</h4>
                                <h6>Manage your purchases</h6>
                            </div>
                        </div> -->


                        <div class="row">
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Pilih Gudang</label>
                                        <model-select :options="warehousedata" placeholder="Pilih Gudang"
                                            v-model="currentData.id_warehouse" required
                                            v-if="currentData.from == 'insert'">
                                        </model-select>
                                        <input type="text" class="form-control form-control-sm"
                                            v-model="currentData.nama" min="0" v-if="currentData.from == 'update'"
                                            required disabled />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="select-split">
                                    <div class="select-group w-100">
                                        <label for="">Unit</label>
                                        <input type="number" class="form-control form-control-sm"
                                            aria-controls="DataTables_Table_0" v-model="currentData.unit" min="0"
                                            required />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="col-lg-12">
                            <button class="btn btn-submit me-2" type="submit">Save</button>
                            <a class="btn btn-cancel" data-bs-dismiss="modal" type="button">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<style></style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ModelSelect } from 'vue-search-select';




import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, rupiah, goPage, AlertBottom } from "@/assets/js/function";
import { boolean } from "yup";

export default {
    data() {
        return {
            //title
            title: "",
            sub: "",

        }

    },

    props: {
        warehousedata: Object,
        currentData: Object,
    },
    watch: {
        currentData: {
            handler(newVal) {
                if (newVal) {
                    if (newVal.id_warehouse != "") {
                        this.title = "Perbaharui Unit didalam Gudang";
                        this.sub = "Perbaharui Unit di gudang";
                    } else {
                        this.title = "Masukan Unit didalam Gudang";
                        this.sub = "Tambah unit di gudang tertentu ";
                    }
                }


            },

        },


    },

    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
    },


    methods: {
        rupiah,
        additem() {
            const item_object = {
                id_warehouse: this.currentData.id_warehouse,
                unit: this.currentData.unit,
                id_item: this.currentData.id_item,
                status: this.currentData.status,
            }
            this.$emit('item_object', item_object)
            const openButton = this.$refs.buttonclosemodaladdproductwarehouse;
            openButton.click();

        },

    },
    components: {
        ModelSelect

    },
}
</script>