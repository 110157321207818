<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">

            <form @submit.prevent="update">
              <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Jenis Invoice <span class="text-danger">*</span></label>
                      <!-- <vue-feather type="plus-circle" class="text-primary" style="cursor: pointer;"
                        @click="goPageCallback('addcustomer', 'addpurchase')"></vue-feather> -->
                    </div>
                    <!-- <model-select :options="data_jenis_invoice" placeholder="Pilih Jenis Invoice"
                      v-model="this.currentData.jenis" style="z-index:1004">
                    </model-select> -->
                    <vue-select :options="jenisInvoiceList" v-model="currentData.jenis" placeholder="None" disabled />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Pelanggan <span class="text-danger">*</span></label>

                    </div>
                    <model-select :options="data_customer" placeholder="Pilih customer"
                      v-model="this.currentData.customer_detail.id" style="z-index:1004">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Ekspedisi<span class="text-danger">*</span></label>
                    </div>
                    <model-select :options="data_ekspedisi" placeholder="Pilih ekspedisi"
                      v-model="this.currentData.id_ekspedisi" style="z-index:1004">
                    </model-select>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No Invoice</label>
                    <input type="text" v-model="currentData.invoice_no" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12"
                  v-if="currentData.sales_order_no != '' && currentData.jenis == 'INVOICE'">
                  <div class="form-group">
                    <label>SO</label>
                    <input type="text" v-model="currentData.sales_order_no" placeholder="" disabled />
                  </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No Sales Invoice </label>
                    <input type="text" v-model="currentData.po_number" placeholder="" />
                  </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group" style="margin-bottom: 0px!important; ">
                    <label>Tanggal Tagihan<span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 1002;">
                      <datepicker class="picker" v-model="currentData.invoice_date" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group" style="margin-bottom: 0px!important; ">
                    <label>Tenggat Waktu<span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 1001;">
                      <datepicker class="picker" v-model="currentData.due_date" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div> -->

                <!-- table item -->
                <div class="row  ">
                  <div class="col-12  mt-4 mb-2 d-flex gap-2 align-items-end justify-content-between">
                    <div class="">
                      Tabel Produk
                    </div>
                    <vue-feather type="plus-circle" class="text-primary" style="cursor: pointer;" @click="addItem"
                      v-if="currentData.jenis == 'INVOICE'"></vue-feather>
                    <vue-feather type="plus-circle" class="text-primary" style="cursor: pointer;" @click="addInvoice"
                      v-if="currentData.jenis == 'SO'"></vue-feather>
                  </div>
                </div>

                <div class="row pe-0">
                  <div class="col-12 pe-0">
                    <div class=" table-responsive mb-3">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>
                              <span v-if="currentData.jenis == 'INVOICE'">Nama Produk</span>
                              <span v-else>No SO </span>
                            </th>
                            <th v-if="currentData.jenis == 'INVOICE'">Description</th>
                            <th v-if="currentData.jenis == 'INVOICE'">
                              Nama Gudang</th>
                            <th v-if="currentData.jenis == 'SO'">Pajak</th>
                            <!-- <th v-if="currentData.jenis == 'SO'">Tipe Pajak</th> -->
                            <!-- <th>Marketing</th> -->
                            <th>Diskon</th>
                            <th>Tipe Diskon</th>
                            <th>Harga</th>
                            <th>Unit</th>
                            <th>Total</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in currentData.invoice_detail" :key="item.id">
                            <td>
                              {{ index + 1 }}
                            </td>

                            <td>
                              <div class="d-flex gap-1 align-items-center">
                                <div>
                                  {{ item.name }}
                                </div>
                                <div v-if="item.on_bungkus == 'AKTIF'">
                                  <i class='bx bx-error-circle text-primary tooltip2'>
                                    <span class="tooltiptext2">Item dengan bungkus</span>
                                  </i>
                                </div>
                              </div>
                            </td>
                            <td v-html="item.deskripsi" v-if="currentData.jenis == 'INVOICE'">
                            </td>
                            <td v-if="currentData.jenis == 'INVOICE'">
                              {{ item.nama_gudang }}
                            </td>
                            <!-- <td>
                              {{ item.nama_marketing }}
                            </td> -->
                            <td v-if="currentData.jenis == 'SO'">
                              <span v-if="item.tax_type == 'persen'">{{ item.tax_amount + ' %' }} </span>
                              <span v-if="item.tax_type == 'rupiah'">{{ rupiah(item.tax_amount) }}</span>
                            </td>
                            <!-- <td v-if="currentData.jenis == 'SO'">
                              {{ item.tax_type }}
                            </td> -->
                            <td>
                              <span v-if="item.discount_type == 'persen'">{{ item.discount_amount + ' %' }} </span>
                              <span v-if="item.discount_type == 'rupiah'">{{ rupiah(item.discount_amount) }}</span>

                            </td>
                            <td>
                              {{ item.discount_type }}
                            </td>
                            <td>

                              {{ rupiah(item.rate) }}
                            </td>

                            <td>
                              {{ item.qty }}
                            </td>
                            <td>

                              {{ rupiah(item.item_total) }}
                            </td>
                            <td>
                              <a href="javascript:void(0);" class="me-2" @click="editItem(item)"
                                v-if="currentData.jenis == 'INVOICE'"> <img src="@/assets/img/icons/edit.svg"
                                  alt="img" /></a>
                              <a href="javascript:void(0);" class="me-2" @click="editInvoice(item)"
                                v-if="currentData.jenis == 'SO'"> <img src="@/assets/img/icons/edit.svg"
                                  alt="img" /></a>
                              <a href="javascript:void(0);" class="text-danger me-2" @click="deleteItem(index)">
                                <vue-feather type="trash"></vue-feather></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="row ps-2 ms-1  pe-3 me-1 mb-4">
                  <div class="col-12 d-flex justify-content-between w-100 ps-0 pe-0" style="border:1px solid #f8f8f8">
                    <div class="w-100 " style="background-color: #fafbfe!important;">
                      <h4 class="order-total">total</h4>
                    </div>
                    <div class="w-100">
                      <h4 class="order-amount">{{ rupiah(totalAmount) }}</h4>
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-between w-100 ps-0 pe-0" style="border:1px solid #f8f8f8">
                    <div class="w-100 " style="background-color: #fafbfe!important;">
                      <h4 class="order-total">Diskon</h4>
                    </div>
                    <div class="w-100 d-flex justify-content-between  align-items-center">
                      <div class="d-flex gap-2">
                        <div class="form-group mb-0">
                          <select class="form-control form-white" data-placeholder="Pilih tipe"
                            v-model="currentData.discount_type">
                            <option value="rupiah">Rp.</option>
                            <option value="persen">%</option>
                          </select>
                        </div>
                        <div class="form-group mb-0">
                          <input type="number" v-model="currentData.calculated_discount" @focus="selectAll"
                            placeholder="" />
                        </div>
                      </div>

                      <div>
                        <h4 class="order-amount">{{ rupiah(currentData.discount_amount) }}</h4>
                      </div>

                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-between w-100 ps-0 pe-0" style="border:1px solid #f8f8f8">
                    <div class="w-100 " style="background-color: #fafbfe!important;">
                      <h4 class="order-total">Pajak {{ currentData.tax_name }}</h4>
                    </div>
                    <div class="w-100 d-flex justify-content-between  align-items-center">
                      <div class="d-flex gap-2">
                        <div class="form-group mb-0">
                          <select class="form-control form-white" data-placeholder="Pilih tipe"
                            v-model="currentData.tax_type" disabled>
                            <option value="persen">%</option>
                            <option value="rupiah">Rp.</option>
                          </select>
                        </div>
                        <div class="form-group mb-0">
                          <input type="number" v-model="currentData.tax_rate" @focus="selectAll" placeholder="" />
                        </div>
                      </div>

                      <div>
                        <h4 class="order-amount">{{
                          rupiah(currentData.tax_amount) }}</h4>
                      </div>

                    </div>

                  </div>
                  <div class="col-12 d-flex justify-content-between w-100 ps-0 pe-0" style="border:1px solid #f8f8f8">
                    <div class="w-100" style="background-color: #fafbfe!important;">
                      <h4 class="order-total">Total Akhir</h4>
                    </div>
                    <div class="w-100 d-flex justify-content-end">
                      <div>
                        <h4 class="order-amount">{{ rupiah(currentData.grand_total) }}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Peraturan Pembayaran</label>
                      <textarea class="form-control" v-model="currentData.quotation_term">
    </textarea>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Catatan Sales Invoice</label>
                      <textarea class="form-control" v-model="currentData.quotation_note">
    </textarea>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Simpan</button>
                  <router-link to="invoice" class="btn btn-cancel">Batal</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>

  <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#modaladditempurchase"
    ref="buttonopenmodaladditempurchase" hidden><i class="fa fa-plus me-2"></i></a>
  <additemsalesordermodal :marketingdata="data_marketing" :kategoridata="data_kategori" :itemdata="data_item_filter"
    :warehousedata="data_warehouse" @id_kategori_selected="handleKategoriSelected"
    @id_item_selected="handleItemSelected" :isMarketing="isMarketing" @item_object="handleItemAdd"
    :itemEdited="itemEdited" :tingkatHarga="tingkatHarga">
  </additemsalesordermodal>
  <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#modaladdinvoice"
    ref="buttonopenmodaladdinvoice" hidden><i class="fa fa-plus me-2"></i></a>
  <addinvoicesalesordermodal :sodata="data_sales_order" @invoice_object="handleInvoiceAdd"
    :invoiceEdited="invoiceEdited">
  </addinvoicesalesordermodal>



</template>

<style scoped>
.order-total {

  color: #637381;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-right: 1px solid #f8f8f8;
  background: #fafbfe;
}

.order-amount {
  color: #212b36;
  font-size: 14px;
  padding: 10px;
  font-weight: 700;
  text-align: right;

}
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom, rupiah, rupiahInput } from "@/assets/js/function";
// import modaladditem from "./modaladditem";
import { ModelSelect } from 'vue-search-select';

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {

    ModelSelect

  },
  name: "edit Invoice",
  data() {
    return {
      filter: true,
      title: "Perbaharui Sales Invoice",
      title1: "perbaharui data Sales Invoice",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentDate: new Date(),
      currentData: {

        customer_detail: {
          id: "",
        },
        invoice_detail: [],
        invoice_payment: [],
        discount_type: "rupiah",
        calculated_discount: "0",
        discount_amount: 0,
        tax_amount: 0,
        tax_rate: 0,
        tax_name: "PPN",
        tax_type: "persen",
        grand_total: 0,
        po_number: "",
        code_project: "",

      },

      data_customer: [],
      data_item: [],
      data_kategori: [],
      data_marketing: [],
      data_payments: [],
      data_bank: [],
      data_warehouse: [],
      data_ekspedisi: [],
      data_sales_order: [],
      jenisInvoiceList: ['INVOICE', 'SO'],

      //to modaladditem
      data_item_filter: [],
      isMarketing: false,
      itemEdited: {
        marketing_id: "",
        kategori_id: "",
        item_id: "",

      },
      invoiceEdited: {
        item_id: "",
      },
      paymentEdited: {
        id: "",
        payment_method: "",
        payment_amount: "",
        payment_notes: "",
        payment_date: new Date(),
      },

      // cek deposit
      status_item_deposit: false,

      //cek payment
      payment_active: "",

      //test 

      batas_payment_edit: 0,

      //rate 
      rate_active: "",

      //pass data 
      pass_data: {
        from: "",
      },

      //bank
      bank_selected: "",
      bankCek: false,

      tingkatHarga: 0,



    };
  },
  computed: {
    totalAmount() {
      if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
        return 0;
      } else {
        const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.item_total), 0)
        return total
      }
    },
    total_payment() {
      if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
        return 0;
      } else {
        const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.payment_amount), 0)
        return total
      }
    },

    batas_payment() {
      let payment = 0;
      let amount = 0;
      if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
        payment = 0;
      } else {
        const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.payment_amount), 0)
        payment = total;
      }
      if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
        amount = 0;
      } else {
        const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.item_total), 0)
        amount = total
      }


      return amount - payment;
    }

  },
  watch: {
    'bank_selected': {
      handler(newValue) {
        this.bankCek = false;
      }
    },
    'currentData.discount_type': {
      handler(newValue) {
        if (this.currentData.calculated_discount == '' || (this.currentData.calculated_discount > 100 && newValue == 'persen')
          || (this.currentData.calculated_discount < 0) || (newValue == 'rupiah' && this.currentData.calculated_discount > this.totalAmount)) {
          this.currentData.calculated_discount = 0;
        }
        if (newValue == 'rupiah') {
          this.currentData.discount_amount = this.currentData.calculated_discount;
        } else if (newValue == 'persen' && this.totalAmount > 0) {
          this.currentData.discount_amount = parseInt(this.currentData.calculated_discount) * (parseInt(this.totalAmount)) / 100;
        } else {
          this.currentData.discount_amount = 0;
        }

        this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
      }
    },
    'currentData.calculated_discount': {
      handler(newValue) {
        if (this.currentData.calculated_discount == '' || (newValue > 100 && this.currentData.discount_type == 'persen')
          || newValue < 0 || (this.currentData.discount_type == 'rupiah' && newValue > this.totalAmount)) {
          this.currentData.calculated_discount = 0;
        }
        if (this.currentData.discount_type == 'rupiah') {
          this.currentData.discount_amount = newValue;
        } else if (this.currentData.discount_type == 'persen' && this.totalAmount > 0) {
          this.currentData.discount_amount = parseInt(newValue) * (parseInt(this.totalAmount)) / 100;
        } else {
          this.currentData.discount_amount = 0;
        }


        this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
      }
    },
    'currentData.tax_rate': {
      handler(newValue) {
        if ((newValue > 100 && this.currentData.tax_type == 'persen') || newValue < 0) {
          this.currentData.tax_rate = 0;
        }

        if (this.currentData.tax_type == 'persen' && this.totalAmount > 0) {
          this.currentData.tax_amount = parseInt(newValue) * (parseInt(this.totalAmount)) / 100;
        } else {
          this.currentData.tax_amount = 0;
        }
        this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
      }
    }
  },
  created() {
    ShowLoading();
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    // get needed data
    this.getData();
    CloseLoading();

    //get data
    const current_id = JSON.parse(localStorage.getItem('currentData'));


    // call api invoice_detail
    this.invoice_detail(current_id);





  },



  methods: {
    rupiah,
    selectAll(event) {
      // Select all text when input field is focused
      event.target.select();
    },
    goPageCallback(url, callback) {
      localStorage.setItem('callback', callback);
      goPage(url);
    },

    cek_deposit() {
      const index = this.currentData.invoice_detail.findIndex((record) => record.isdeposit == 'AKTIF');
      const indexPayment = this.data_payments.findIndex((record) => record.nama == 'DEPOSIT');
      const indexcustomer = this.data_customer.findIndex((record) => record.id == this.currentData.customer_detail.id);
      if (index > -1) {
        this.status_item_deposit = true;
      } else {
        this.status_item_deposit = false;
      }

      if ((indexPayment > -1 && index > -1) || (indexcustomer > -1 && this.data_customer[indexcustomer].amount_deposit <= 0)) {
        this.data_payments.splice(indexPayment, 1);

      } else if (indexcustomer > -1 && this.data_customer[indexcustomer].amount_deposit > 0 && indexPayment < 0) {
        this.data_payments.push({ text: 'DEPOSIT (' + rupiah(this.data_customer[indexcustomer].amount_deposit) + ')', value: this.data_payments.length, nama: "DEPOSIT" })
      }

    },
    handleKategoriSelected(item) {
      if (item != "") {
        this.data_item_filter = this.dataItemsFilterKategori(item);
      } else {
        this.data_item_filter = this.data_item;
      }

    },

    dataItemsFilterKategori(id) {
      return this.data_item.filter(item => {
        // Apply your filtering condition
        if (item.id_kategori == id) {
          // Modify the 'value' attribute here
          item.text = item.nama;// Your modification logic here;
          return true; // Keep this item in the filtered result
        }
        return false; // Exclude this item from the filtered result
      });
    },
    handleItemSelected(item) {
      this.isMarketing = item;
    },
    handleItemAdd(item) {

      const index = this.data_item.findIndex((record) => record.id == item.id_items);
      const indexMarketing = this.data_marketing.findIndex((record) => record.id == item.id_marketing);
      const indexWarehouse = this.data_warehouse.findIndex((record) => record.id == item.id_warehouse);

      const indexKeranjangUpdate = this.currentData.invoice_detail.findIndex(
        (record) => record.id == item.id
      );

      const indexWarehouseInvoiceDetail = this.currentData.invoice_detail.findIndex(
        (record) => record.id_warehouse == item.id_warehouse && record.id_items == item.id_items
      );




      const rate_active = this.cek_rate_active();
      let nama_marketing = "";
      if (this.data_item[index].ismarketing == 'AKTIF' && this.data_marketing[indexMarketing] != undefined) {
        nama_marketing = this.data_marketing[indexMarketing].fullname;
      }

      let total_harga_item = 0;
      let total_discount = 0;
      if (item.discount_type == 'persen') {
        total_harga_item = item.harga - (item.harga * (item.discount / 100));
        total_discount = (item.harga * (item.discount / 100));
      } else {
        total_harga_item = item.harga - item.discount;
        total_discount = item.discount;
      }


      const itemPrepare = {
        ...item, name: this.data_item[index].nama,
        on_bungkus: this.data_item[index].on_bungkus,
        isdeposit: this.data_item[index].isdeposit,
        deskripsi: this.data_item[index].deskripsi,
        nama_marketing: nama_marketing,
        rate: item.harga,
        discount_type: item.discount_type,
        discount_amount: item.discount,
        item_calculated_discount: total_discount,
        item_total: item.qty * parseInt(total_harga_item),
        id_warehouse: item.id_warehouse,
        nama_gudang: this.data_warehouse[indexWarehouse].text,
      };

      this.cek_deposit();

      if (item.status == 'tambah') {
        if (indexWarehouseInvoiceDetail > -1) {
          AlertPopup('error', '', 'Produk dengan gudang tersebut sudah terdaftar', 2000, true);
          return true;
        }
        itemPrepare.id = this.currentData.invoice_detail.length;


        if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK') {
          this.currentData.invoice_detail.push(itemPrepare);
        } else if (this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF') {
          this.currentData.invoice_detail.push(itemPrepare);
        } else if (this.status_item_deposit == false && this.currentData.invoice_detail.length == 0 && this.data_item[index].isdeposit == 'AKTIF') {
          this.currentData.invoice_detail.push(itemPrepare);
        }
        else {
          AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
        }
      } else {
        if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK' && indexKeranjangUpdate > -1) {
          this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;
        } else if ((this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1) || ((this.status_item_deposit == false && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1 && this.currentData.invoice_detail.length == 1))) {
          if (this.data_item[index].isdeposit == 'AKTIF' && this.currentData.invoice_detail.length == 1) {
            this.currentData.invoice_payment = [];
          }
          this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;

        } else if (indexKeranjangUpdate == -1) {

          AlertPopup('error', '', 'item tidak ditemukan', 2000, true);
        }
        else {
          AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
        }
      }
      this.cek_deposit();

      this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;

    },
    handleInvoiceAdd(item) {
      // const index = this.data_item.findIndex((record) => record.id == item.id_items);
      // const indexMarketing = this.data_marketing.findIndex((record) => record.id == item.id_marketing);
      // const indexWarehouse = this.data_warehouse.findIndex((record) => record.id == item.id_warehouse);

      const indexKeranjangUpdate = this.currentData.invoice_detail.findIndex(
        (record) => record.id == item.id
      );

      // const indexWarehouseInvoiceDetail = this.currentData.invoice_detail.findIndex(
      //   (record) => record.id_warehouse == item.id_warehouse && record.id_items == item.id_items
      // );

      // const rate_active = this.cek_rate_active();
      // let nama_marketing = "";
      // if (this.data_item[index].ismarketing == 'AKTIF' && this.data_marketing[indexMarketing] != undefined) {
      //   nama_marketing = this.data_marketing[indexMarketing].fullname;
      // }

      // let total_harga_item = 0;
      // let total_discount = 0;
      // if (item.discount_type == 'persen') {
      //   total_harga_item = item.harga - (item.harga * (item.discount / 100));
      //   total_discount = (item.harga * (item.discount / 100));
      // } else {
      //   total_harga_item = item.harga - item.discount;
      //   total_discount = item.discount;
      // }

      const index_sales_order = this.data_sales_order.findIndex((record) => record.id === item.id);
      const itemPrepare = {
        ...item,
        name: this.data_sales_order[index_sales_order].text,
        qty: 1,

        on_bungkus: 'TIDAK',
        isdeposit: 'TIDAK',
        deskripsi: '',
        nama_marketing: '',
        rate: this.data_sales_order[index_sales_order].subtotal,
        discount_type: this.data_sales_order[index_sales_order].discount_type,
        discount: this.data_sales_order[index_sales_order].discount_amount,
        calculated_discount: this.data_sales_order[index_sales_order].calculated_discount,
        item_total: this.data_sales_order[index_sales_order].grand_total,
        id_warehouse: '',
        nama_gudang: '',
        tax_amount: this.data_sales_order[index_sales_order].tax_amount,
        tax_type: this.data_sales_order[index_sales_order].tax_type,
        // name: this.data_item[index].nama,
        // isdeposit: this.data_item[index].isdeposit,
        // on_bungkus: this.data_item[index].on_bungkus,
        // deskripsi: this.data_item[index].deskripsi,
        // nama_marketing: nama_marketing,
        // rate: item.harga,
        // discount_type: item.discount_type,
        // discount_amount: item.discount,
        // calculated_discount: total_discount,
        // item_total: item.qty * parseInt(total_harga_item),
        // id_warehouse: item.id_warehouse,
        // nama_gudang: this.data_warehouse[indexWarehouse].text,
      };

      console.log(this.data_sales_order[index_sales_order].discount_amount)

      // this.cek_deposit();



      if (item.status == 'tambah') {

        if (indexKeranjangUpdate > -1) {
          AlertPopup('error', '', 'SO sudah terdaftar', 2000, true);
          return true;
        }

        // itemPrepare.id = this.currentData.invoice_detail.length;

        // if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK') {
        //   this.currentData.invoice_detail.push(itemPrepare);
        // } else if (this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF') {
        //   this.currentData.invoice_detail.push(itemPrepare);
        // } else if (this.status_item_deposit == false && this.currentData.invoice_detail.length == 0 && this.data_item[index].isdeposit == 'AKTIF') {
        //   this.currentData.invoice_detail.push(itemPrepare);
        // }
        // else {
        //   AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
        // }

        this.currentData.invoice_detail.push(itemPrepare)
      } else {

        this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;
        // if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK' && indexKeranjangUpdate > -1) {
        //   this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;
        // } else if ((this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1) || ((this.status_item_deposit == false && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1 && this.currentData.invoice_detail.length == 1))) {
        //   if (this.data_item[index].isdeposit == 'AKTIF' && this.currentData.invoice_detail.length == 1) {
        //     this.currentData.invoice_payment = [];
        //   }
        //   this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;

        // } else if (indexKeranjangUpdate == -1) {
        //   AlertPopup('error', '', 'item tidak ditemukan', 2000, true);
        // }
        // else {
        //   AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
        // }

      }
      this.cek_deposit();

      this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;

    },

    cek_rate_active() {
      let status = "rate";


      if (this.currentData.customer_detail.id == "0" || this.currentData.customer_detail.id == 0) {
        status = 'rate';
      } else {
        const indexcustomer = this.data_customer.findIndex((record) => record.id === this.currentData.customer_detail.id);

        if (indexcustomer == -1) {
          status = "rate";
        } else if (this.data_customer[indexcustomer].rate == "0") {
          status = "rate";
        } else {
          status = 'rate' + this.data_customer[indexcustomer].rate;
        }
      }
      this.rate_active = status;
      return status;
    },
    deleteItem(indexToRemove) {
      this.currentData.invoice_detail.splice(indexToRemove, 1);
      this.cek_deposit();
      if (this.currentData.invoice_detail.length == 0) {
        this.currentData.invoice_payment = [];
      }

      this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
    },
    invoice_detail(current_id) {

      ShowLoading();
      let formData = new FormData();
      formData.append('id', current_id);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'invoices_details';
      } else {
        this.api = base_url + 'invoices_details';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {

            this.currentData = response.data.data.invoices[0];
            console.log(this.currentData);
            // this.currentData.invoice_detail = [{}];
            // const invoicearray = response.data.data.invoices[0].invoice_detail;
            // invoicearray.forEach((value, index) => {
            //   value.id = index + 1;
            //   this.currentData.invoice_detail.push(value);
            // });
            this.cek_deposit();
            this.currentData.invoice_date = new Date(response.data.data.invoices[0].invoice_date)
            this.currentData.payment_date = new Date(response.data.data.invoices[0].payment_date)
            this.currentData.due_date = new Date(response.data.data.invoices[0].due_date)
            this.cek_rate_active();

            if (this.currentData.tax_type != 'persen') {
              this.currentData.tax_type = 'persen';
            }

            if (this.currentData.discount_type != 'persen' && this.currentData.discount_type != 'rupiah') {
              this.currentData.discount_type = 'persen';
            }

            if (this.currentData.bank_account_number != '') {
              const indexBank = this.data_bank.findIndex((record) => record.nomor_rekening == this.currentData.bank_account_number);

              if (indexBank > -1) {
                this.bank_selected = this.data_bank[indexBank].id
              } else {
                this.bank_selected = '';
              }
            }


            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    getData() {
      const customer = JSON.parse(localStorage.getItem('customer'));
      customer.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.fullname };
        this.data_customer.push(item_prepare)
      });

      //get data kategori 
      const kategori = JSON.parse(localStorage.getItem('kategori'));
      kategori.forEach((item, index) => {

        let item_prepare = { ...item, value: item.id, text: item.nama };
        this.data_kategori.push(item_prepare)
      });

      const item = JSON.parse(localStorage.getItem('item'));
      item.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.nama, };
        this.data_item.push(item_prepare)
      });

      const data_warehouse = JSON.parse(localStorage.getItem('warehouse'));
      data_warehouse.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.nama, };
        this.data_warehouse.push(item_prepare)
      });

      const data_ekspedisi = JSON.parse(localStorage.getItem('ekspedisi'));
      data_ekspedisi.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.nama, };
        this.data_ekspedisi.push(item_prepare)
      });

      const data_sales_order = JSON.parse(localStorage.getItem('sales_order'));
      data_sales_order.forEach((item, index) => {
        let item_prepare = { ...item, value: item.id, text: item.so_no, };
        this.data_sales_order.push(item_prepare)
      });

      if (localStorage.getItem('pass_data') != null) {
        this.pass_data = JSON.parse(localStorage.getItem('pass_data'));
        if (this.pass_data.from == 'customer') {
          const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
          this.data_customer.unshift(item_prepare);
        } else if (this.pass_data.from == 'marketing') {
          const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
          this.data_marketing.unshift(item_prepare);
        }
      }

      if (localStorage.getItem('pass_data') != null) {
        if (this.pass_data.from == 'marketing') {
          localStorage.setItem('marketing', JSON.stringify(this.data_marketing));
        } else if (this.pass_data.from == 'customer') {
          localStorage.setItem('customer', JSON.stringify(this.data_customer));
        }
        localStorage.removeItem('pass_data');
      }



    },
    addItem() {

      //cek harga customer
      const index_customer = this.data_customer.findIndex((record) => record.id === this.currentData.customer_detail.id);

      this.tingkatHarga = this.data_customer[index_customer].rate;

      this.itemEdited = {
        marketing_id: "",
        kategori_id: "",
        id_warehouse: "",
        item_id: "",
        qty: 1,
        status: 'tambah',
        discount_type: 'persen',
        harga: 0,
        discount: 0,
      }

      const openButton = this.$refs.buttonopenmodaladditempurchase;
      openButton.click();

    },
    addInvoice() {
      if (this.currentData.customer_detail.id == '') {
        AlertPopup('error', '', 'Silahkan Pilih Pelanggan terebih dahulu ', 2000, true);
        return true;
      }
      //cek harga customer
      // const index_sales_order = this.data_sales_order.findIndex((record) => record.id === this.currentData.sales_order_detail.id);
      // this.tingkatHarga = this.data_customer[index_customer].rate;

      this.invoiceEdited = {
        item_id: "",
        qty: 1,
        status: 'tambah',
      }
      const openButton = this.$refs.buttonopenmodaladdinvoice;
      openButton.click();

    },
    editItem(item) {
      //cek harga customer
      const index_customer = this.data_customer.findIndex((record) => record.id === this.currentData.customer_detail.id);


      this.tingkatHarga = this.data_customer[index_customer].rate;

      const index = this.data_item.findIndex((record) => record.id == item.id_items);
      const indexKategori = this.data_kategori.findIndex((record) => record.id == this.data_item[index].id_kategori);

      this.itemEdited = {
        marketing_id: item.id_marketing,
        kategori_id: this.data_kategori[indexKategori].id,
        item_id: item.id_items,
        id_warehouse: item.id_warehouse,
        qty: item.qty,
        status: 'edit',
        harga: parseInt(item.rate),
        discount_type: item.discount_type,
        discount_amount: parseInt(item.discount_amount),
        id: item.id,
      }
      const openButton = this.$refs.buttonopenmodaladditempurchase;
      openButton.click();
    },
    editInvoice(item) {

      // const index = this.data_item.findIndex((record) => record.id == item.id_items);
      // const indexKategori = this.data_kategori.findIndex((record) => record.id == this.data_item[index].id_kategori);

      // //cek harga customer
      // const index_customer = this.data_customer.findIndex((record) => record.id === this.currentData.customer_detail.id);

      // this.tingkatHarga = this.data_customer[index_customer].rate;

      const index_sales_order = this.data_sales_order.findIndex((record) => record.text === item.name);

      this.invoiceEdited = {
        marketing_id: item.id_marketing,
        kategori_id: '',
        item_id: item.id_items,
        qty: item.qty,
        status: 'edit',
        harga: parseInt(item.rate),
        discount_type: item.discount_type,
        discount_amount: parseInt(item.discount_amount),
        id_warehouse: item.id_warehouse,
        // id: item.id,
        id: this.data_sales_order[index_sales_order].id,
      }
      const openButton = this.$refs.buttonopenmodaladdinvoice;
      openButton.click();
    },

    //payment
    handlePaymentAdd(item) {
      const indexKeranjangUpdate = this.currentData.invoice_payment.findIndex(
        (record) => record.id == item.id
      );
      item.payment_method = this.data_payments[item.payment_method].nama;

      if (item.status == 'tambah') {
        item.id = this.currentData.invoice_payment.length;

        this.currentData.invoice_payment.push(item);
      } else {
        this.currentData.invoice_payment[indexKeranjangUpdate] = item;
      }
    },


    addPayment() {
      this.paymentEdited = {
        id: "",
        payment_method: "",
        payment_amount: rupiahInput(0),
        payment_notes: "",
        payment_date: new Date(),
        status: 'tambah'
      }

      const openButton = this.$refs.buttonopenmodalpayment;
      openButton.click();


    },
    editPayment(id, payment_amount, payment_method, payment_date, payment_notes) {

      const indexPayment = this.data_payments.findIndex((list) => list.nama == payment_method);
      const paymentobject = {
        id: id,
        payment_method: indexPayment,
        payment_amount: rupiahInput(parseInt(payment_amount)),
        payment_notes: payment_notes,
        payment_date: new Date(payment_date),
        status: 'update'
      }


      // total amount 
      let amount = 0;
      if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
        amount = 0;
      } else {
        const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.item_total), 0)
        amount = total;
      }

      let payment = 0;
      if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
        payment = 0;
      } else {
        const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
          parseInt(currentValue.payment_amount), 0)
        payment = total
      }

      this.batas_payment_edit = parseInt(amount) - parseInt(payment) + parseInt(payment_amount);

      this.paymentEdited = paymentobject;
      const openButton = this.$refs.buttonopenmodalpayment;
      openButton.click();
    },
    deletePayment(index) {
      this.currentData.invoice_payment.splice(index, 1);



    },

    convertDate(value) {
      const date = new Date(value);

      // Use built-in methods to get year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    update() {

      if (this.currentData.invoice_detail.length == 0) {
        AlertPopup("error", "", 'Please select item for sale', 1500, false);
        return true;
      }





      ShowLoading();
      let formData = new FormData();
      const currentDate = new Date().toISOString().split('T')[0];

      const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
        parseInt(currentValue.item_total), 0);
      let item_id = [];
      let item_qty = [];
      let item_rate = [];
      let item_discount_type = [];
      let item_discount_amount = [];
      let item_tax_type = [];
      let item_tax_amount = [];
      let item_is_tax = [];
      let item_name = [];
      let item_warehouse = [];
      let item_calculated_discount = [];
      let item_total = [];
      let item_isdeposit = [];
      let item_id_marketing = [];
      let payment_amount = [0];
      let payment_method = [0];
      let payment_date = [0];
      let payment_notes = [0];


      this.currentData.invoice_detail.forEach((item) => {
        item_id.push(item.id_items)
        item_qty.push(item.qty)

        // const indexItem = this.data_items.findIndex((list) => list.id === item.id_items);
        item_rate.push(item.rate);
        item_discount_type.push(item.discount_type);
        item_discount_amount.push(item.discount_amount);
        item_tax_type.push(item.tax_type);
        item_tax_amount.push(item.tax_amount);
        item_is_tax.push(0);
        item_name.push(item.name);
        item_calculated_discount.push(item.item_calculated_discount);
        item_total.push(item.item_total);
        item_isdeposit.push(item.isdeposit);
        item_id_marketing.push(item.id_marketing);
        item_warehouse.push(item.id_warehouse);

      });


      // yang dicomment udh ada
      formData.append("id", this.currentData.id);
      formData.append("invoice_date", this.convertDate(this.currentData.invoice_date)); //udh ada
      formData.append("due_date", this.convertDate(this.currentData.due_date)); //udh ada 
      formData.append("terms", 0); //kosong
      formData.append("po_number", this.currentData.po_number); //kosong
      formData.append("id_customer", this.currentData.customer_detail.id); //udh ada
      formData.append("id_ekspedisi", this.currentData.id_ekspedisi);
      formData.append("subtotal", this.totalAmount);// udh ada 
      formData.append("discount_type", this.currentData.discount_type); //kosong
      formData.append("discount_amount", this.currentData.discount_amount);//kosong
      formData.append("tax_type", this.currentData.tax_type);//kosong
      formData.append("tax_rate", this.currentData.tax_rate);//kosong

      formData.append("tax_name", 'PPN');//kosong
      formData.append("tax_amount", this.currentData.tax_amount);
      formData.append("payment_date", payment_date); //udh ada
      formData.append("payment_amount", payment_amount);
      formData.append("payment_method", payment_method);
      formData.append("payment_notes", payment_notes); //kosong
      formData.append("unpaid_amount", '');//kosong
      formData.append("grand_total", this.currentData.grand_total); // udh ada 
      formData.append("notes", ''); //kosong
      formData.append("payment_info", ''); //kosong

      formData.append("bank_name", ""); //kosong
      formData.append("bank_account_number", ""); //kosong
      formData.append("bank_account_name", ""); //kosong


      formData.append("calculated_discount", this.currentData.calculated_discount); //kosong

      formData.append("item_id", item_id);// udh ada
      formData.append("item_qty", item_qty); //udh ada
      formData.append("item_rate", item_rate); //udh ada
      formData.append("item_discount_type", item_discount_type); //kosong
      formData.append("item_discount_amount", item_discount_amount); //kosong
      formData.append("item_tax_type", item_tax_type); //kosong
      formData.append("item_tax_amount", item_tax_amount); //kosong
      formData.append("item_is_tax", item_is_tax); //kosong
      formData.append("item_name", item_name); //kosong
      formData.append("item_warehouse", item_warehouse); //kosong
      formData.append("item_calculated_discount", item_calculated_discount); //kosong
      formData.append("item_total", item_total);// udh ada 
      formData.append("item_isdeposit", item_isdeposit); // udh ada 
      formData.append("item_id_marketing", item_id_marketing);// udh ada

      formData.append("id_marketing", this.currentData.id_marketing);
      formData.append("po_number", this.currentData.po_number);
      formData.append("code_project", this.currentData.code_project);
      formData.append("quotation_term", this.currentData.quotation_term);// udh ada
      formData.append("quotation_note", this.currentData.quotation_note);// udh ada
      formData.append("jenis", this.currentData.jenis);

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // CloseLoading();
      // return false;

      if (this.currentData.jenis == 'INVOICE') {
        if (process.env.NODE_ENV === "development") {
          this.api = base_url + 'invoices_update';
        } else {
          this.api = base_url + 'invoices_update';
        }
      } else {
        if (process.env.NODE_ENV === "development") {
          this.api = base_url + 'invoices_update_multiple_so';
        } else {
          this.api = base_url + 'invoices_update_multiple_so';
        }
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage('invoice');
            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    }


  },

};
</script>
